import React, {useState, useEffect} from "react"
import ImgBuilder from "../../../res/img/imgBuilder"
import { Dropdown } from "react-bootstrap"
import { inject, observer } from "mobx-react"

import "./styles.scss"

const DropdownInput = (props) =>{
    
    const {
        data, 
        dropDownIcon = "", 
        type = "", 
        placeholder = false, 
        title = "",
        setCurrentItem = (item) => item,
        opportunitiesModel:{
            opportunitiesList, 
            forecastCategory,
            setForecastCategory},
        userModel:{filteredUser}
    } = props
 
    let listTitles = data
    let storagedForecastItem = localStorage.getItem("forcastType")

    const defineActiveItemAfterChanges = () =>{
        return placeholder ? 
        false : 
        (type === "forecast" ? 
            ( storagedForecastItem ? 
                storagedForecastItem : 
                listTitles[listTitles.length - 1].title)
            : 
            listTitles[0])
    }

    const [activeitem, setActiveItem] = useState(defineActiveItemAfterChanges())
    const [arrowActive, setArrowActive] = useState(ImgBuilder.filterArrowDown)
    
    useEffect(()=>{
        activeitem && setCurrentItem(activeitem)
    },[activeitem])

    useEffect(() => {
        if(type === "forecast"){
            setActiveItem("All");
            setForecastCategory("All")
        }
    }, [filteredUser])

    useEffect(()=>{
        if(type === "mainTableFilter" && opportunitiesList.length){
            setActiveItem(listTitles[0])
        }
    },[opportunitiesList])

    return <div className = "containerDropdownInput">
        <div className = "dropdownDropdownInput">
            <Dropdown >
                <Dropdown.Toggle 
                    id="dropdown-basic" 
                    className = {`${type !== "mainTableFilter" ? "p-2" : ""}`}
                    onMouseOver = {() => {type === "mainTableFilter" && setArrowActive(ImgBuilder.filterArrowDownBlue)}}
                    onMouseLeave = {() => {type === "mainTableFilter" && setArrowActive(ImgBuilder.filterArrowDown)}}
                    >
                    {type !== "mainTableFilter" ? <div className="flex"><div className = "personIconDropdownInput">
                        <img src = {dropDownIcon} style = {{width: "14px"}}/>
                    </div>
                    <div className = {`chosenItemTextDropdownInput ${!activeitem ? "placeholderDropdown" : ""}`}>
                        {activeitem ? (activeitem?.title ? activeitem?.title : activeitem) : placeholder}
                    </div>
                    </div> : <span>{title}</span>}
                    <div>
                        <img src = {arrowActive} style = {{width: "10px"}}/>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className = "mt-0">
                    {!["forecast", "server"].includes(type) ? 
                        listTitles.map((item, index) => <Dropdown.Item onClick = {() => {setActiveItem(item)}} key = {index}>{item}</Dropdown.Item>):
                        listTitles.map((item, index) => <Dropdown.Item onClick = {() => {setActiveItem(item?.number ? item : item.title)}} key = {index}>
                            <span>{item.title}</span>
                            {type === "forecast" ? <span className = "countItemDropdownInput">{item.count}</span> : null}
                        </Dropdown.Item>)
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    </div>
}

export default inject(
    "opportunitiesModel",
    "userModel"
)(observer(DropdownInput))