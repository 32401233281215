import mainlogo from "./mainlogo.svg"
import mainlogoTM from "./mainlogoTM.svg"
import personIcon from "./personIcon.svg"
import filterArrowDown from "./filterArrowDown.svg"
import filterArrowDownBlue from "./filterArrowDownBlue.svg"
import arrowLeft from "./arrowLeft.svg"
import arrowRight from "./arrowRight.svg"
import forcastFilter from "./forcastFilter.svg"
import emptyPageImage from "./emptyPageImage.svg"
import loginImage from "./loginImage.svg"
import backgroundLogo from "./backgroundLogo.svg"
import refreshIcon from "./refreshIcon.svg"
import loader from "./loader.svg"
import messageIcon from './messageIcon.svg'
import messageIconActive from './messageIconActive.svg'
import closeMessagesIcon from './closeMessagesIcon.svg'
import sendMessageArrowIcon from './sendMessageArrowIcon.svg'
import sendMessageErrorIcon from "./sendMessageErrorIcon.svg"
import resendMessageIcon from "./resendMessageIcon.svg"
import trashIcon from "./trashIcon.svg"
import expandIcon from "./expandIcon.svg"
import dragMenuIcon from "./dragMenuIcon.svg"
import makeLessIcon from "./makeLessIcon.svg"
import messageIconActiveWhite from "./messageIconActiveWhite.svg"
import messageIconWhite from "./messageIconWhite.svg"
import resizeModalIcon from "./resizeModalIcon.svg"
import whiteTriangularIcon from './whiteTriangularIcon.svg'

const ImgBuilder ={
    mainlogo,
    personIcon,
    filterArrowDown,
    arrowRight,
    arrowLeft,
    forcastFilter,
    emptyPageImage,
    loginImage,
    mainlogoTM,
    backgroundLogo,
    filterArrowDownBlue,
    refreshIcon,
    loader,
    messageIcon,
    messageIconActive,
    closeMessagesIcon,
    sendMessageArrowIcon,
    sendMessageErrorIcon,
    resendMessageIcon,
    trashIcon,
    expandIcon,
    dragMenuIcon,
    makeLessIcon,
    messageIconActiveWhite,
    messageIconWhite,
    resizeModalIcon,
    whiteTriangularIcon
}

export default ImgBuilder