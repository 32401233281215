import React, {useState, useRef, useEffect, useReducer} from 'react'
import { observer, inject } from "mobx-react"
import ImgBuilder from '../../../res/img/imgBuilder'

const NewMessages = (props) => {
    const {
        showMenu,
        setShowMenu,
        sendMessageHandler,
        messageStatusDisplay,
        displayMessages,
        firstMessageToday,
        chatsModel : {
            chatUploadedMessages,
            setChatUploadedMessages,
            lastReadMessageId
        }
    } = props

    const handleErrorAction = (action , id, message) => {
        if(action === "delete"){
            let copyChatUploadedMessages = chatUploadedMessages
            setChatUploadedMessages(copyChatUploadedMessages.filter(message => message.id !== id))
        }else{
            sendMessageHandler(message, true, id)
        }
        setShowMenu({id:null, value:false})
    }

    return <div>
        
        {chatUploadedMessages.length && firstMessageToday ? <div className="messagesChatDate">
            Today
        </div> : null}
        
        {chatUploadedMessages.map((message, index) => {
            return <div className= {`messageChatContainer ${message.myMessage ? "" : "notMyMessageContainer"} ${!message.onServer ? "messageDeliveryError" : ""}`} key = {index}> 
                {message.myMessage ? 
                    <>
                        <>   
                            <div className="messageItem myMessage">
                                {displayMessages(message.message)}
                            </div>
                            <div className="messageStatus">
                                {lastReadMessageId === message.id ? "Read" : messageStatusDisplay(message.messageStatus, message.id)}
                                { message?.messageStatus ? <span>&nbsp;{message.time}</span> : null}
                            </div>
                        </>
                        <div style = {{
                            display : `${message.onServer ? "none" : "flex"}`,
                        }}>
                            <button 
                                className="messageChatError" 
                                style = {{display:`${message.onServer && "none"}`}}
                                onClick={() => 
                                    showMenu.id === null ? 
                                        setShowMenu({id:index, value: true}) : 
                                        setShowMenu({id:null, value:false})
                                }
                            >
                                <img src = {ImgBuilder.sendMessageErrorIcon}/>
                            </button>
                        </div>
                        <>   
                            <div className = "messageErrorMenuContainer" style = {{display:`${showMenu.id !== index ? "none": "inherit"}`}}>
                                <div className='messageErrorMenuItem ordinaryItem' onClick={() => handleErrorAction("resend", message.id, message.message)}>
                                    <img src = {ImgBuilder.resendMessageIcon}/>
                                    <span>Resend</span>
                                </div>
                                <div className='messageErrorMenuItem redItem' onClick={() => handleErrorAction("delete", message.id, message.message)}>
                                    <img src = {ImgBuilder.trashIcon}/>
                                    <span>Delete Message</span>
                                </div>
                            </div>
                        </>
                    </>
                :
                <div>
                    <div className="messageItem notMyMessage" key = {index}>
                        {displayMessages(message.message)}
                    </div>
                    <div className="messageStatus">
                        <span>{message.time}</span>
                    </div>
                </div>}

            </div>
        })
    }
    </div>
}

export default inject(
    "chatsModel"
)(observer(NewMessages))