import React, {useState, useRef, useEffect, useReducer} from 'react'
import MessagesData from '../../MessagesData'
import ImgBuilder from '../../../res/img/imgBuilder'
import { observer, inject } from "mobx-react"


const MessagesPreviewInside = (props) =>{
    const {
        setOpenModalObject,
        handleCloseMessagePreview,
        setTransparent,
        handleClose,
        draggable,
        myMessages,
        showMenu,
        setShowMenu,
        setOpenBigModal,
        setMyMessages,
        opportunityId,
        chatsModel:{
            setCurrentChatDetails
        },
        userModel:{
            needToRefresh
        }
    } = props
    return <div>
        {draggable ? 
            <div 
                className='messagesPreviewDrag' 
            >
                <img src = {ImgBuilder.dragMenuIcon}/>
            </div>: 
            <div style = {{height:"0px"}}/>
        }
        <div className='messagesPreviewHeaderActions'>
            <div className='messagesPreviewOpenModal' onClick={() => {
                if(!needToRefresh){
                    setOpenBigModal(false);
                    setTimeout(() => {
                        setOpenModalObject({show:true, opportunityId: opportunityId})
                        setTransparent(true)
                        draggable && handleCloseMessagePreview()
                    }, 10)
                }
            }}>
                <img src = {ImgBuilder.expandIcon}/>
            </div>
            <div onClick={() => {
                handleClose()
                setCurrentChatDetails({})
            }} 
                className = 'messagesPreviewClose'
            >
                <img src = {ImgBuilder.closeMessagesIcon}/>
            </div>
        </div>
        <MessagesData 
            myMessages = {myMessages}
            setMyMessages = {setMyMessages}
            showMenu = {showMenu}
            setShowMenu = {setShowMenu}
            type = "preview"
        />
    </div>
}

export default inject(
    "chatsModel",
    "userModel"
)(observer(MessagesPreviewInside))