import { makeAutoObservable } from "mobx"
import axios from 'axios'
import { authModel } from './auth'
import { baseAPIAdmin } from '../res/consts/settings'
import { opportunitiesModel } from './opportunities'
import { userModel } from './user'
import { serverAPImodel } from './serverAPI'

export class ChatsModel {
    constructor(){
        this.chatsList = []
        this.currentChatMessagesData = {}
        this.currentChatDetails = {}
        this.chatDetails = {}
        this.messagesDataLoading = false
        this.chatDetailsLoading = false
        this.readMessageLoading = false
        this.socketOpenedState = false
        this.lastSentMessage = {}
        this.messagesDisplayed = []
        this.openedChat = false
        this.isTyping = {typingStatus:false, chatId: null}
        this.lastReadStatusId = ""
        this.clickNotificationUserName = ""
        this.chatUploadedMessages = []
        this.chatsWithUnreadedMessages = []
        this.socketOpenedList = []
        makeAutoObservable(this)
    }
    setSocketOpenedList = (data) => {
        this.socketOpenedList = data
    }

    setChatsWithUnreadedMessages = (data) => {
        this.chatsWithUnreadedMessages = data
    }
    
    setChatUploadedMessages = data => {
        const messagesDisplayed = this.messagesDisplayed
        const messagesDisplayedIds = messagesDisplayed.map(msg => msg.id)
        const newData = data.filter(msg => !messagesDisplayedIds.includes(msg.id))
        this.chatUploadedMessages = newData
    }

    setLastReadStatusId = data =>{
        this.lastReadStatusId = data
    }

    setClickNotificationUserName = (data) => {
        this.clickNotificationUserName = data
    }
    
    setIsTyping = (data) => {
        this.isTyping = data
    }

    setOpenedChat = data => {
        this.openedChat = data
    }

    setChatDetails = data =>{
        this.chatDetails = data
    }

    setMessagesDisplayed = (data) =>{
        this.messagesDisplayed = data
    }

    setLastSentMessage = (data) => {
        const chat_id = data.data.chat_id;
        const id = data.data.id;
        this.lastSentMessage = data;
    }

    setSocketOpenedState = data =>{
        this.socketOpenedState = data
    }

    setChatDetailsLoading = (data) =>{
        this.chatDetailsLoading = data
    }

    setReadMessageLoading = (data) =>{
        this.readMessageLoading = data
    }

    setChatsList = (data) =>{
        console.log("data", data)
        const setChatsWithUnreadedMessages = this.setChatsWithUnreadedMessages
        this.chatsList = data
        let copydata = data
        
        let draftChatsWithUnreadedMessages = copydata.filter(chat => chat?.last_message?.status !== "read" && 
            userModel.userListIds.includes(chat?.last_message?.user_id)
        )
        let newChatsWithUnreadedMessages = draftChatsWithUnreadedMessages.map(chat => chat.id)
        setChatsWithUnreadedMessages(newChatsWithUnreadedMessages)
    }

    setMessagesDataLoading = (data) =>{
        this.messagesDataLoading = data
    }

    setCurrentChatMessagesDataEmpty = () =>{
        this.currentChatMessagesData = {}
    }

    setCurrentChatMessagesData = (data) =>{
        console.log("messages data", JSON.parse(JSON.stringify(data)))
        const setMessagesDataLoading = this.setMessagesDataLoading
        const setMessagesDisplayed = this.setMessagesDisplayed
        this.setLastReadStatusId("")
        const dateEquaty = (date1,date2) =>{
            return !!(date1.day == date2.day && date1.month == date2.month && date1.year == date2.year)
        }
        const monthNameList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        const monthDaysCount = [31, (new Date().getFullYear() % 4) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31,30, 31]
        
        const checkIfYesterday = (date) => {
            let now  = new Date()
            
            if(now.getFullYear() == date.getFullYear()+1 && date.getMonth() == 11 && date.getDate() == 31 && now.getDate() == 1 && now.getMonth() == 0){
                return true
            }else if(date.getMonth() == now.getMonth() - 1 && now.getDate() == 1 && date.getDate() == monthDaysCount[date.getMonth()]){
                return true
            }else if(now.getDate() == date.getDate() + 1){
                return true
            }
            return false
        }
        const locale = navigator.language
        let timeFormat = Intl.DateTimeFormat(locale,  { hour: 'numeric' }).resolvedOptions().hourCycle
        let dateList = data.map((message, index) => {
            
            let date = new Date(message.created_at)
            let copyDate = date
            let offset = copyDate.getTimezoneOffset()
            let updDate = new Date(copyDate - offset)
            let now  = new Date()
            let result = {
                day: updDate.getDate(), 
                month: monthNameList[updDate.getMonth()], 
                year: now.getFullYear() === updDate.getFullYear() ? "" :updDate.getFullYear(),
                time:date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: timeFormat !== "h23" }),
                today: !!(now.getDate() == date.getDate() && now.getMonth() == date.getMonth() && now.getFullYear() == date.getFullYear()),
                yesterday: checkIfYesterday(date),
            }
            setMessagesDataLoading(false)
            return result
        })
        let formatedData = data.map((messageItem, index) =>{
            let result = {
                date:dateList[index],
                titleDate: (index === data.length - 1) || (index <= dateList.length - 2 && !dateEquaty(dateList[index], dateList[index+1])),
                myMessages:!userModel.userListIds.includes(messageItem.user_id),
                status:messageItem.status,
                message:messageItem.message,
                id: messageItem.id,
                showRead: (messageItem.status === "read" && !(!!this.lastReadStatusId) && !userModel.userListIds.includes(messageItem.user_id)) ? true : false
            }
            messageItem.status === "read" && !(!!this.lastReadStatusId) && !userModel.userListIds.includes(messageItem.user_id) && this.setLastReadStatusId(messageItem.id)
            return result
        })
        this.currentChatMessagesData = formatedData
        setMessagesDisplayed(formatedData.slice(0,10))
    }

    setCurrentChatDetails = (data) =>{
        this.currentChatDetails = data
    }
    setChatDetails = (data) =>{
        this.chatDetails = data
    }

    getChatsList = () =>{
        const setChatsList = this.setChatsList
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": authModel.auth_token
            },
        }
        axios.get(`${serverAPImodel.baseAPIAdmin}chats`, headers)
        .then(function (response) {
            setChatsList(response.data.chats);
        })
        .catch(function (error) {
            console.log('error', error);
        })
    }

    getCurrentChatMessagesData = (chatId) =>{
        const setChatDetailsLoading = this.setChatDetailsLoading
        const setCurrentChatMessagesData = this.setCurrentChatMessagesData
        const setMessagesDataLoading = this.setMessagesDataLoading
        const setChatUploadedMessages = this.setChatUploadedMessages
        const setChatsWithUnreadedMessages = this.setChatsWithUnreadedMessages
        const chatsWithUnreadedMessages = this.chatsWithUnreadedMessages
        if(chatsWithUnreadedMessages.includes(chatId)){
            let newChatsWithUnreadedMessages = chatsWithUnreadedMessages.filter(id => chatId !== id)
            setChatsWithUnreadedMessages(newChatsWithUnreadedMessages)
        }
        setChatUploadedMessages([])
        const messagesDataLoading = this.messagesDataLoading
        //if(!messagesDataLoading){
            setChatDetailsLoading(true)
            const headers = {
                "headers":{
                    "accept": "application/json, text/plain, */*",
                    "auth-token": authModel.auth_token
                },
            }
            setMessagesDataLoading(true)
            axios.get(`${serverAPImodel.baseAPIAdmin}chats/${chatId}/messages`, headers)
            .then(function (response) {
                setCurrentChatMessagesData(response.data.messages);
                setChatDetailsLoading(false)
            })
            .catch(function (error) {
                console.log('error', error);
            })
        //}
    }

    setMoreMessagesDisplayed = (count) =>{
        const currentChatMessagesData = this.currentChatMessagesData
        const setMessagesDisplayed = this.setMessagesDisplayed
        const messagesDisplayed = this.messagesDisplayed

        if(currentChatMessagesData.length >= count){
            let newMessagesDisplayed = currentChatMessagesData.slice(0,count)
            setMessagesDisplayed(newMessagesDisplayed)
        }else if(currentChatMessagesData.length === messagesDisplayed.length){
            return
        }else{
            setMessagesDisplayed(currentChatMessagesData)
        }
    }

    createChat = (opportunityId) =>{
        const getChatsList = this.getChatsList
        const setCurrentChatDetails = this.setCurrentChatDetails
        const getCurrentChatMessagesData = this.getCurrentChatMessagesData
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": authModel.auth_token
            }
            
        }
        const params = {opportunity_id : opportunityId}
        axios.post(`${serverAPImodel.baseAPIAdmin}chats`, params, headers)
        .then(function (response) {
            // setCurrentChatDetails(response.data)
            // getCurrentChatMessagesData(response.data.id)
            getChatsList()
            return response.data
        })
        .catch(function (error) {
            console.log('error', error);
        })
    }

    sendMessage = (message, resend) =>{
        if(message){
            const currentChatDetails = this.currentChatDetails
            const getCurrentChatMessagesData = this.getCurrentChatMessagesData
            const setLastSentMessage = this.setLastSentMessage

            let copyMessage = message
            let charArrayReversed = copyMessage.split("\n").reverse()
            let firstChar = charArrayReversed.find(item => item !== "")
            let indexOfChar = charArrayReversed.indexOf(firstChar)
            charArrayReversed.splice(0,indexOfChar)
            let charArray = charArrayReversed.reverse()
            let formatedMessage = ""
            charArray.filter((item, index) => {
                formatedMessage += item
                if(index < charArray.length - 1){
                    formatedMessage += "\n" 
                }
            })

            const headers = {
                "headers":{
                    "accept": "application/json, text/plain, */*",
                    "auth-token": authModel.auth_token
                } 
            }
            const params = {"message": formatedMessage, "resend": resend}
            return axios.post(`${serverAPImodel.baseAPIAdmin}chats/${currentChatDetails.id}/message`, params, headers)
            .then(function(response){
                setLastSentMessage(response)
                return "Success"
            })
            .catch(function (error) {
                console.log('error', error);
                return "Fail"
            })
        }  
    }

    readMessage = (messageIds) =>{
        //const setReadMessageLoading = this.setReadMessageLoading
        const readMessageLoading = this.readMessageLoading
        //if(!readMessageLoading){
        //setReadMessageLoading(true)
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": authModel.auth_token
            } 
        }
        let unreadCoint = messageIds.length
        const recursion = (iteration) =>{
            if(iteration < unreadCoint){
                axios.post(`${serverAPImodel.baseAPIAdmin}messages/${messageIds[iteration]}/read`, {}, headers)
                .then(function(){
                    let newIteration = iteration + 1
                    return recursion(newIteration)
                })
                .catch(function (error) {
                    console.log('error', error);
                })
            }
        }
        recursion(0)
    }

    setTypingStatus = (chatId, status) =>{
       
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": authModel.auth_token
            } 
        }
        const params = {typing : status} 
        axios.post(`${serverAPImodel.baseAPIAdmin}chats/${chatId}/typing`, params, headers)
       
        .catch(function (error) {
            console.log('error', error);
        })
    
    }

    getCurrentChatDetails = (opportunityId) =>{
        const chatsList = this.chatsList
        const setCurrentChatDetails = this.setCurrentChatDetails
        const createChat = this.createChat
        const setChatUploadedMessages = this.setChatUploadedMessages
        const getCurrentChatMessagesData = this.getCurrentChatMessagesData
        setChatUploadedMessages([])
        let currentChatDataDraft = chatsList.find((chat => chat?.opportunity_id == opportunityId))
        if(currentChatDataDraft){
            setCurrentChatDetails(currentChatDataDraft)
            getCurrentChatMessagesData(currentChatDataDraft.id)
            return currentChatDataDraft
        }else{
            let result = createChat(opportunityId)
            return result
            
        }
    }
}

export const chatsModel = new ChatsModel