import React, {useState, useRef, useEffect} from 'react'
import ModalPattern from '../ModalPattern'
import ImgBuilder from '../../res/img/imgBuilder'
import MessagesData from '../MessagesData'
import mockedMessages from './mockedData'
import { observer, inject } from "mobx-react"
import "./styles.scss"

const MessagesModal = (props) => {
    const {
        openModalImage,
        customHandleMessages = false,
        withIcon,
        openModal = false,
        opModal,
        setOpenModalObject,
        handleShowMessagePreview = false,
        type = "",
        chatsModel:{
            setOpenedChat,
            setCurrentChatDetails
        },
        authModel : {
            tableSizeParams
        }
    } = props
   
    const [inputVal, setInputVal] = useState("")
    const [myMessages, setMyMessages] = useState(mockedMessages)
    const [showMenu, setShowMenu] = useState({id:null, value:false})

    const customCloseModalFunction = () =>{
        setInputVal("")
        setMyMessages(mockedMessages)
        setShowMenu({id:null, value:false})
        setOpenModalObject({show:false})
        if(handleShowMessagePreview && tableSizeParams.width > 960){
            handleShowMessagePreview()
        }else{
            setCurrentChatDetails({})
        };
        setOpenedChat(false)
    }

    useEffect(() => {
        customHandleMessages && customHandleMessages()
        openModal && setOpenedChat(true)
    }, [])

    useEffect(() => {
        openModal && setOpenedChat(true) 
    }, [openModal])

    return <div className="messagesModalContainer">
        <ModalPattern
            withIcon = {withIcon}
            modalTriggerButton = {<img src = {openModalImage}/>}
            customModalClass = {"messagesModalContainer"} //нужно для иконки закрытия модала
            openModal = {openModal}
            opModal={opModal}
            withResize = {true}
            modalContent = {
                <MessagesData 
                    inputVal = {inputVal}
                    setInputVal = {setInputVal}
                    myMessages = {myMessages}
                    setMyMessages = {setMyMessages}
                    showMenu = {showMenu}
                    setShowMenu = {setShowMenu}
                    type = "modal"
                />
            }
            oncloseCustomFunction = {customCloseModalFunction} 
        />
    </div>
}

export default inject(
    "chatsModel",
    "authModel"
)(observer(MessagesModal))