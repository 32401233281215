const mockedMessages = [
    {  
        date: "October 29, 2021, 3:54 PM",
        text: "Dave oh my God! It's out of ice! Like some outer space! Dave oh my God! It's out of ice! Like some outer space!",
        my:false,
        id:0
    },
    {
        my:true,
        text:"Wow! That ice dispenser's so big, the  ice crushes you! Yakov Smirnov said it!",
        time: new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
        read:true,
        delivered: true,
        id:1
    },
    {
        my:true,
        text:"Wow! That ice dispenser's so big, the  ice crushes you! Yakov Smirnov said it!",
        time: new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
        delivered: false,
        read:false,
        id:2
    }
]

export default mockedMessages