import { makeAutoObservable } from "mobx"
import axios from "axios"
import { baseAPIAdmin } from "../res/consts/settings"
import { authModel } from "./auth"
import { objectivesModel } from './objectives'
import { serverAPImodel } from './serverAPI'
import { chatsModel } from './chats'
import { userModel } from './user'

export class OpportunitiesModel{
    constructor(){
        this.opportunitiesList = []
        this.currentOppotunity = null
        this.forecastCategory = "All"
        this.opportunitiesListCommited = []
        this.opportunitiesListExpected = []
        this.opportunitiesListUpside = []
        this.checkPushState = false;
        this.opportunitiesListPipeline = []
        makeAutoObservable(this)
    }

    setCheckPushState = (state) => {
        this.checkPushState = state;
    }

    setOpportunitiesList = opportunitiesList => {
        this.opportunitiesList = [...opportunitiesList].reverse().sort((a,b) => new Date(a.close_date) - new Date(b.close_date))
    }

    setForecastCategory = forecastCategory =>{
        this.forecastCategory = forecastCategory
    }

    setCurrentOppotunity = opportunity =>{
        this.currentOppotunity = opportunity
        objectivesModel.buildDiagramsData(opportunity)
        localStorage.setItem("currentOppotunityId", JSON.stringify(opportunity.company.id))
    }

    setForecastFilteredLists = (data, cat) =>{
        switch(cat){
            case("Committed"):
                this.opportunitiesListCommited = data
                break
            case("Expected"):
                this.opportunitiesListExpected = data
                break
            case("Upside"):
                this.opportunitiesListUpside = data
                break
            case("Pipeline"):
                this.opportunitiesListPipeline = data
                break
        }
    }
    
    checkIfUpdatedDisplayedFields = (userFilteredId, opportunityId) => {
        const opportunitiesList = this.opportunitiesList;
        const setOpportunitiesList = this.setOpportunitiesList;
        const filterOpportunitiesListBeforeSaving = this.filterOpportunitiesListBeforeSaving;
        const checkIfUpdatedObjectiveIsCritical = this.checkIfUpdatedObjectiveIsCritical;
        const parametersObject = {user_id: userFilteredId}
        const notIntrestedFields = [
            "action_item_id",
            "category_id",
            "created_at",
            "id",
            "parthners_id_list",
            "updated_at",
            "cases",
            "opp_company"
        ]
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": authModel.auth_token
            },
            params:parametersObject
        }
        axios.get(`${serverAPImodel.baseAPIAdmin}opportunities`, headers)
        .then(function(response){
            const updatedOpportunityPreviousState = opportunitiesList.filter(opp => opp.id === opportunityId).length ? opportunitiesList.filter(opp => opp.id === opportunityId)[0] : null
            const updatedOpportunityCurrentState = response.data.opportunities.filter(opp => opp.id === opportunityId).length ?  response.data.opportunities.filter(opp => opp.id === opportunityId)[0] : null
            if(!updatedOpportunityPreviousState && !updatedOpportunityCurrentState){return false}

            if (!updatedOpportunityCurrentState) {
                userModel.setNeedToRefresh(true);
                return;
            }
     
            if(!updatedOpportunityPreviousState && updatedOpportunityCurrentState) {
                userModel.setNeedToRefresh(true);
                return;
            }

            if(updatedOpportunityPreviousState){
                const removedPrevObjectives = updatedOpportunityPreviousState.removed_objectives_id_list.split(';');
                const removedCurrentObjectives = updatedOpportunityCurrentState.removed_objectives_id_list.split(';');
                const prevObjectives = updatedOpportunityPreviousState.objectives_id_list.split(';');
                const currentObjectives = updatedOpportunityCurrentState.objectives_id_list.split(';');
                const removedPrevObjectivesLength = removedPrevObjectives.length;
                const removedCurrentObjectivesLength = removedCurrentObjectives.length;

                if (prevObjectives.length < currentObjectives.length) {
                    setOpportunitiesList(filterOpportunitiesListBeforeSaving(response.data.opportunities));
                    return;
                }

                if (prevObjectives.length === currentObjectives.length) {
                    const prevObjectivesIndex = {};
                    const currentObjectiveIndex = {};
                    const changedKeyArr = [];

                    if (updatedOpportunityPreviousState.objectives_id_list !== updatedOpportunityCurrentState.objectives_id_list) {
                        prevObjectives.forEach((obj, index) => {
                            prevObjectivesIndex[index] = obj;
                        })

                        currentObjectives.forEach((obj, index) => {
                            currentObjectiveIndex[index] = obj;
                        })

                        for (let key in prevObjectives) {
                            if (prevObjectives[key] !== currentObjectives[key]) {
                                changedKeyArr.push(currentObjectives[key]);
                            }
                        }

                        const objectiveIds = {};

                        changedKeyArr.forEach((key, index) => {
                            objectiveIds[key] = index;
                            objectiveIds[index] = key;
                        });

                        checkIfUpdatedObjectiveIsCritical(userFilteredId, objectiveIds[0], objectiveIds);
                        return;
                    }
                }

                if (removedPrevObjectivesLength
                    !== removedCurrentObjectivesLength && removedCurrentObjectivesLength > removedPrevObjectivesLength) {
                    const removedObjectiveId = removedCurrentObjectives.find(item =>
                        !removedPrevObjectives.includes(item));

                    checkIfUpdatedObjectiveIsCritical(userFilteredId, removedObjectiveId);
                    setOpportunitiesList(filterOpportunitiesListBeforeSaving(response.data.opportunities));
                    return;
                }

                if (removedPrevObjectivesLength
                    !== removedCurrentObjectivesLength && removedCurrentObjectivesLength < removedPrevObjectivesLength) {
                    const addedObjectiveId = removedPrevObjectives.find(item =>
                        !removedCurrentObjectives.includes(item));

                    checkIfUpdatedObjectiveIsCritical(userFilteredId, addedObjectiveId);
                    setOpportunitiesList(filterOpportunitiesListBeforeSaving(response.data.opportunities));
                    return;
                }

                if(!updatedOpportunityCurrentState){
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.objectives_id_list !== updatedOpportunityCurrentState.objectives_id_list) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.opp_company.name !== updatedOpportunityCurrentState.opp_company.name) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.cases.length && updatedOpportunityCurrentState.cases.length && updatedOpportunityPreviousState.cases[0].name !== updatedOpportunityCurrentState.cases[0].name) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (!updatedOpportunityPreviousState.cases.length && updatedOpportunityCurrentState.cases.length) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.cases.length && !updatedOpportunityCurrentState.cases.length) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.name !== updatedOpportunityCurrentState.name) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.opp_product && updatedOpportunityCurrentState.opp_product && updatedOpportunityPreviousState.opp_product.name !== updatedOpportunityCurrentState.opp_product.name) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (!updatedOpportunityPreviousState.opp_product && updatedOpportunityCurrentState.opp_product) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.opp_product && !updatedOpportunityCurrentState.opp_product) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.amount !== updatedOpportunityCurrentState.amount) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.close_date !== updatedOpportunityCurrentState.close_date) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.opportunity_relationships_strength !== updatedOpportunityCurrentState.opportunity_relationships_strength) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.opportunity_urgency !== updatedOpportunityCurrentState.opportunity_urgency) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.opportunity_strategic_business_impact !== updatedOpportunityCurrentState.opportunity_strategic_business_impact) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.opportunity_status !== updatedOpportunityCurrentState.opportunity_status) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                if (updatedOpportunityPreviousState.category !== updatedOpportunityCurrentState.category) {
                    userModel.setNeedToRefresh(true);
                    return;
                }

                let keys = Object.keys(updatedOpportunityCurrentState).filter(key => !notIntrestedFields.includes(key))
                let result = keys.some(key => updatedOpportunityPreviousState[key] !== updatedOpportunityCurrentState[key])
                let changedCompany = updatedOpportunityPreviousState?.opp_company?.name !== updatedOpportunityCurrentState?.opp_company?.name
                if(result || changedCompany){return;}
            }
        })
    }

    getOpportunitiesList = (userFilteredId, forecastCat = null) => {
        const setOpportunitiesList = this.setOpportunitiesList
        const setForecastFilteredLists = this.setForecastFilteredLists
        const setCheckPushState = this.setCheckPushState;
        const parametersObject = {user_id: userFilteredId}
        forecastCat && (parametersObject.category = forecastCat)
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": authModel.auth_token
            },
            params:parametersObject
        }

        axios.get(`${serverAPImodel.baseAPIAdmin}opportunities`, headers)
        .then(function (response) {
            let filteredOpportunitiesByQuestions = (response.data.opportunities.length && objectivesModel.objectivesWithCriticalQuestions.length) ?
            response.data.opportunities.filter(opp =>
                objectivesModel.objectivesWithCriticalQuestions.some(objId => opp.objectives_id_list.includes(objId))
            ) :
                [];
            forecastCat ? 
            setForecastFilteredLists(filteredOpportunitiesByQuestions, forecastCat) : 
            setOpportunitiesList(response.data.opportunities);
            setCheckPushState(true);
            if(!forecastCat){
                let opportunitiesArray = filteredOpportunitiesByQuestions.map(opp => opp.id)
                let newChatsOpportunities = opportunitiesArray.filter((opp => !chatsModel.chatsList.some((chat => chat?.opportunity_id === opp))))
                if(!!newChatsOpportunities.length){
                    newChatsOpportunities.filter(opp => chatsModel.createChat(opp))
                }
            }
        })
        .catch(function (error) {
            console.log('error', error);
        })
    }

    filterOpportunitiesListBeforeSaving = (opportunities) => {
        return opportunities;
    }

    checkIfUpdatedObjectiveIsCritical = (userFilteredId, objectiveId, objectiveIds) => {
        const checkIfUpdatedObjectiveIsCritical = this.checkIfUpdatedObjectiveIsCritical;
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": authModel.auth_token
            },
            params:{
                user_id: userFilteredId
            }
        }
        axios.get(`${serverAPImodel.baseAPIAdmin}objectives/${objectiveId}`, headers)
            .then(function(response){
                const currentObjective = response.data;

                const hasCriticalQuestions = currentObjective && currentObjective.topics
                    && currentObjective.topics
                        .find(topic => topic.questions && topic.questions.find(question => question.critical));

                if(hasCriticalQuestions){
                    userModel.setNeedToRefresh(true);
                } else if (objectiveIds && objectiveIds[objectiveIds[objectiveId] + 1]){
                    checkIfUpdatedObjectiveIsCritical(userFilteredId, objectiveIds[objectiveIds[objectiveId] + 1], objectiveIds)
                }
            });
    }
}

export const opportunitiesModel = new OpportunitiesModel