import React, {useState, useRef} from 'react'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import "./styles.scss"
import { observer, inject } from "mobx-react"

const ModalPattern = (props) => {
    const {
        opportunitiesModel: {
            checkPushState,
        },
        authModel:{
            tableSizeParams
        },
        modalTriggerButton,
        customModalClass,
        modalContent,
        oncloseCustomFunction,
        withIcon,
        withResize = false,
        openModal,
        opModal, 
        chatsModel : {
            clickNotificationUserName,
            setCurrentChatDetails,
        },
    } = props
    const [show, setShow] = useState(opModal)

    useEffect(() => {
        if(opModal && tableSizeParams.width >= 960) {
            const notifyChatData = JSON.stringify(clickNotificationUserName)
            setCurrentChatDetails({id: notifyChatData.chat_id});
            handleClose();
        }
    }, [checkPushState])

    const handleClose = () => {
        setShow(false)
        oncloseCustomFunction()
    }
    const handleShow = () => setShow(true)    

    useEffect(() => {
        openModal && setShow(true)
    }, [openModal])

    return <>
        {withIcon ? 
            <button variant="primary" onClick={handleShow} className="modalPatternOpenButton">
                {modalTriggerButton}
            </button> : 
            null
        }
        <Modal 
            show={show} 
            onHide={handleClose} 
            dialogClassName="modal-620px"
            centered
            className = {`modalPatternContainer ${customModalClass}`}
        >
            <div style ={{display: `${withResize ? "flex": "inherit"}`, padding: "12px"}}>
                {withResize ? <Modal.Header closeButton className='resizeModalButton'/> : null}
                <Modal.Header closeButton/>
            </div>
            <Modal.Body>{modalContent}</Modal.Body>
      </Modal>
    </>
}

export default inject(
    "opportunitiesModel",
    "authModel",
    "chatsModel"
)(observer(ModalPattern))