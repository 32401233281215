import React, {useState, useRef, useEffect, useReducer} from 'react'
import ImgBuilder from '../../res/img/imgBuilder'
import { observer, inject } from "mobx-react"
import useChatSocket from '../../sockets/chat'
import InfiniteScroll from 'react-infinite-scroll-component'
import TypingComponent from './TypingComponent'
import TextareaInput from '../TextareaInput'
import NewMessages from './NewMessages'

import "./styles.scss"

const MessagesData = (props) =>{
    const {
        showMenu,
        setShowMenu,
        type = "",
        chatsModel : {
            currentChatMessagesData, 
            sendMessage, 
            currentChatDetails, 
            getCurrentChatMessagesData, 
            readMessage,
            socketOpenedState,
            setSocketOpenedState,
            messagesDisplayed,
            setMoreMessagesDisplayed,
            setIsTyping,
            chatUploadedMessages,
            setChatUploadedMessages,
            lastReadStatusId,
            setLastReadStatusId,
            isTyping,
            socketOpenedList,
            setSocketOpenedList,
        },
        authModel:{
            currenManager
        },
        opportunitiesModel:{currentOppotunity},
        serverAPImodel:{socketUrl, baseAPI},
        userModel:{userListIds}
    } = props

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const innerRef = useRef();
    const [perPage, updatePerPage] = useState(10)
    const [myUnreadMessagesIdState, setMyUnreadMessagesIdState] = useState([])
    const [lastReadMessageId, setLastReadMessageId] = useState("")
    const [renderMessagesCount, setRenderMessagesCount] = useState(0)
    const [renderTupingCount, setRenderTupingCount] = useState(0)
    const [newMessagesList, updateNewMessagesList] = useState([])
    const [chatHeight, setChatHeight] = useState(0)
    const [triggeredReadStatus, setTriggeredReadStatus] = useState(1)
    const chatRef = useRef(null)

    const sendMessageHandler = (data, alreadyTried = false, id = false) => {
        if(data && ![" ","\n"].includes(data)){
            (async() => {
                let requestStatus = await sendMessage(data, alreadyTried)
                if(requestStatus === "Fail" && !alreadyTried){
                    let copyChatUploadedMessages = chatUploadedMessages
                    copyChatUploadedMessages.push({
                        message: data, 
                        onServer : false,
                        myMessage : true,
                        id: new Date() - 0
                    })
                    setChatUploadedMessages(copyChatUploadedMessages)
                }else if(requestStatus !== "Fail" && alreadyTried && id){
                    let copyChatUploadedMessages = chatUploadedMessages
                    setChatUploadedMessages(copyChatUploadedMessages.filter(message => message.id !== id))
                }
            })();
        }
        return false
    }

    const loadMore = () => {
        const newPerPage = perPage + 10;
        setMoreMessagesDisplayed(newPerPage);
        updatePerPage(newPerPage);
    }

    let wsLink = ""
    switch(baseAPI){
        case ("https://spotlogicunit4.pp.ua/api/v1/"):
            wsLink = "wss://spotlogicunit4.pp.ua/cable/"
            break
        case ("https://spotlogicqaserver.pp.ua/api/v1/"):
            wsLink = "wss://spotlogicqaserver.pp.ua/cable/"
            break
        case ("https://xerox.pp.ua/api/v1/"):
            wsLink = "wss://xerox.pp.ua/cable/"
            break
        case ("https://spotlogic-mlogica.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-mlogica.pp.ua/cable/"
            break
        case ("https://netappspotlogic.pp.ua/api/v1/"):
            wsLink = "wss://netappspotlogic.pp.ua/cable/"
            break
        case ("https://spotlogic-matterport.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-matterport.pp.ua/cable/"
            break
        case ("https://spotlogic-solrenewables.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-solrenewables.pp.ua/cable/"
            break
        case ("https://spotlogic-scholarpath.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-scholarpath.pp.ua/cable/"
            break
    }

    const socket = useChatSocket(wsLink, socketOpenedState, setSocketOpenedState, socketOpenedList, setSocketOpenedList, currentChatDetails.id) 

    const locale = navigator.language
    let timeFormat = Intl.DateTimeFormat(locale,  { hour: 'numeric' }).resolvedOptions().hourCycle

    const parseSocketMessage = (message) => {
        const data = JSON.parse(message.data);
        
        //filter ping messages
        if(data.type === "ping"){
            return;
        }else if(data?.message?.message){
            let copyChatUploadedMessages = chatUploadedMessages;
            let newMessageObject = {
                message: data?.message?.message,
                time:new Date(data.message?.created_at).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: timeFormat !== "h23" }),
                myMessage: !userListIds.includes(data?.message?.user_id),
                onServer: true,
                messageStatus: data?.message?.status,
                id: data?.message?.id
            };

            copyChatUploadedMessages.push(newMessageObject);
            setChatUploadedMessages(copyChatUploadedMessages);       
            ["sent", "resend"].includes(newMessageObject.messageStatus) && userListIds.includes(data?.message?.user_id) && localStorage.getItem("openedChat") === "true" && readMessage([newMessageObject.id])
            return data.message;
        }else if(data?.message?.event === "typing" && userListIds.includes(data?.message?.user_id)){
            setIsTyping({typingStatus: eval(data?.message?.typing), chatId: currentChatDetails.id})
        }else if(data?.message?.event === "read"){
            let chatUploadedMessagesId = chatUploadedMessages.length ? chatUploadedMessages.map(msg => msg.id) : []
            let currentChatMessagesDataId = currentChatMessagesData.length ? currentChatMessagesData.map(msg => msg.id) : []

            if(chatUploadedMessages.some(message => (message.id === data?.message?.id) && message.myMessage) && (chatUploadedMessagesId.indexOf(lastReadStatusId) < chatUploadedMessagesId.indexOf(data?.message?.id))){
                setLastReadStatusId(data?.message?.id)
            }else if(currentChatMessagesData.some(message => (message.id === data?.message?.id) && message.myMessages) && 
                !chatUploadedMessagesId.includes(lastReadStatusId) && 
                ((currentChatMessagesDataId.indexOf(lastReadStatusId) > currentChatMessagesDataId.indexOf(data?.message?.id)))){
                setLastReadStatusId(data?.message?.id)
            }
            let newTriggeredReadStatus = triggeredReadStatus + 1
            setTriggeredReadStatus(newTriggeredReadStatus)
        }
    }

    useEffect(() => {
        if (socket && currentChatDetails?.id) {
          socket.onmessage = (message) => {
            parseSocketMessage(message);
            
          };
        }
        
      }, [socket, currentChatMessagesData, chatUploadedMessages, lastReadStatusId]); 

    useEffect(() => {
        return () => socket && socket.close()
    }, [socket])

    useEffect(() => {
        if(currentChatMessagesData.length){
            let unreadedMessagesId = currentChatMessagesData.filter(item => !item.myMessages && ["sent", "resend"].includes(item.status)).map(item => item.id);
            let myUnreadedMessagesId = currentChatMessagesData.filter(item => item.myMessages && ["sent", "resend"].includes(item.status)).map(item => item.id);
            setMyUnreadMessagesIdState(myUnreadedMessagesId)
            if(unreadedMessagesId.length){
                readMessage(unreadedMessagesId)
            }
            let newRenderMessagesCount = renderMessagesCount + 1
            setRenderMessagesCount(newRenderMessagesCount)
        }
        
    }, [currentChatMessagesData])

    const messageStatusDisplay = (status, id) =>{
        if(lastReadStatusId === id){
            return "Read"
        }else if(["sent", "resend"].includes(status)){
            let lastReadIndex = "unset"
            let currentIndex
            chatUploadedMessages.filter((message, messageIndex) => {
                if(message.id === lastReadStatusId){
                    lastReadIndex = messageIndex
                }else if(message.id === id){
                    currentIndex = messageIndex
                }
            })
            if(lastReadIndex !== "unset" && currentIndex < lastReadIndex){
                return ""
            }else if(lastReadIndex !== "unset" && currentIndex > lastReadIndex){
                return "Delivered"
            }else if(lastReadIndex !== "unset"){
                return ""
            }else if(chatUploadedMessages.length && status == "resend"){
                return "Delivered after resent"
            
            }else if( !chatUploadedMessages.length || status == "resend"){
                let currentChatMessagesDataReadedId = "unset"
                let currentChatMessagesDataIndex
                currentChatMessagesData.filter((msg, msgIndex) => {
                    if(msg.id === lastReadStatusId){
                        currentChatMessagesDataReadedId = msgIndex
                    }else if(msg.id === id){
                        currentChatMessagesDataIndex = msgIndex
                    }
                })
                if(currentChatMessagesDataReadedId > currentChatMessagesDataIndex){
                    if(status === "sent"){
                        return "Delivered"
                    }else{
                        return "Delivered after resent"
                    }
                }else{
                    return ""
                }
            }
            return "Delivered"
        }else if(status == "read"){
            return (lastReadStatusId === id) ? "Read" : ""
        }else{
            return "Not Delivered"
        }
    }

    const displayMessages = (message) =>{
        let arrString = message.split("\n")
        let newArr = []
        arrString.filter((item, index) => {
            newArr.push(item)
            index + 1 < arrString.length && newArr.push(<br/>)
        })
        return <div>{newArr.map(item => item)}</div>
    }
    useEffect(() => {
        if(chatRef?.current?.clientHeight){
            setChatHeight(chatRef?.current?.clientHeight)
        } 
    }, {chatRef})

    
    return <div className="messagesModalContent">
        <div className="messagesModalHeader" style = {{marginTop:`${type === "modal" && "-40px"}`}}>
            <span>Opportunity Chat</span>
            {currentOppotunity?.company?.opp_company?.name ? <span className="messagesModalHeaderComapny">{currentOppotunity?.company?.opp_company?.name}</span> : null}
        </div>
        <div className="messagesConteiner" style = {{overflowY : `${showMenu.value ? "hidden" : "auto"}`}}>
            <div 
                className = "messagesData" 
                id = "messagesData" 
                ref = {chatRef} 
                style = {{
                    flexDirection:`${currentChatMessagesData.length ? 
                        "column-reverse" : 
                        "column"}`,
                    height: `${
                        type === "modal" ? 
                            (window.innerHeight < 728 && 
                            `${window.innerHeight - 255}px`) :
                            (type === "preview" && 
                                (window.innerHeight < 655 && 
                                `${window.innerHeight - 190}px`))
                    }`,
                    maxHeight: `${
                            (type === "preview" && `${(window.innerHeight - 190)*2/3}px`)
                    }`,   
                    overflowY:`${showMenu.value ? "unset" : "auto"}`
                }}>
                {currentChatMessagesData.length ? <div className="messagesChatContainer">
                    <InfiniteScroll
                        dataLength={messagesDisplayed.length}
                        scrollableTarget="messagesData"
                        next={() => loadMore()}
                        hasMore={true}
                        style = {{
                            overflow:"hidden", 
                            display:"flex", 
                            flexDirection:"column-reverse",
                        }}
                        inverse = {true}
                    >
                        {messagesDisplayed.map((message, index) => <div key = {index}>
                            {message.titleDate ? 
                                <div className="messagesChatDate">
                                    {message.date.today ? 
                                        "Today" : 
                                        (message.date.yesterday ? 
                                            "Yesterday" :
                                            `${message.date.month} ${message.date.day} ${message.date.year} `
                                        )
                                    }
                                    {false ? <span>{message.date.time}</span> : null}
                                </div>: 
                                null
                            }
                            {message.myMessages ? 
                                <div className= {`messageChatContainer ${message.status === "fail" && "messageDeliveryError"}`}>
                                    <div> 
                                        <div className="messageItem myMessage" key = {index}>
                                            {displayMessages(message.message)}
                                        </div>
                                        <div className="messageStatus">
                                            {lastReadMessageId}   
                                            {(triggeredReadStatus && lastReadMessageId === message.id) ? "Read" : messageStatusDisplay(message.status, message.id)}
                                            {["sent", "read", "resend"].includes(message.status) ? <span>&nbsp;{message.date.time}</span> : null}
                                        </div>
                                    </div>
                                    {message.status === "fail" ? 
                                        <button 
                                            className="messageChatError" 
                                            onClick={() => 
                                                showMenu.id === null ? 
                                                    setShowMenu({id:index, value: true}) : 
                                                    setShowMenu({id:null, value:false})
                                            }
                                        >
                                            <img src = {ImgBuilder.sendMessageErrorIcon}/>
                                        </button>: 
                                        null}
                                    
                                    {//showMenu.id === index ? 
                                        false ?
                                        <div className = "messageErrorMenuContainer">
                                            <div className='messageErrorMenuItem ordinaryItem' onClick={() => setShowMenu({id:null, value:false})}>
                                                <img src = {ImgBuilder.resendMessageIcon}/>
                                                <span>Resend</span>
                                            </div>
                                            <div className='messageErrorMenuItem redItem' onClick={() => setShowMenu({id:null, value:false})}>
                                                <img src = {ImgBuilder.trashIcon}/>
                                                <span>Delete Message</span>
                                            </div>
                                        </div>: 
                                        null
                                    }
                                </div>:
                                <div className= "messageChatContainer notMyMessageContainer">
                                    <div className="messageItem notMyMessage" key = {index}>
                                        {displayMessages(message.message)}
                                    </div>
                                    <div className="messageStatus">
                                        <span>{message.date.time}</span>
                                    </div>
                                </div>
                            }
                        </div>)
                        }
                    </InfiniteScroll>
                    <NewMessages
                        showMenu = {showMenu}
                        setShowMenu = {setShowMenu}
                        sendMessageHandler = {sendMessageHandler}
                        messageStatusDisplay = {messageStatusDisplay}
                        displayMessages = {displayMessages}
                        firstMessageToday = {!(currentChatMessagesData.length && currentChatMessagesData[0].date.today)}
                    />
                </div> : 
                (!(!!chatUploadedMessages.length) ? 
                    <div className="messagesConteinerEmpty">No messages yet</div>:
                    <div className="messagesChatContainer" style = {{marginTop:"auto"}}>
                        <NewMessages
                            showMenu = {showMenu}
                            setShowMenu = {setShowMenu}
                            sendMessageHandler = {sendMessageHandler}
                            messageStatusDisplay = {messageStatusDisplay}
                            displayMessages = {displayMessages}
                            firstMessageToday = {!(currentChatMessagesData.length && currentChatMessagesData[0].date.today)}
                        />
                    </div>
                )
                }
                <TypingComponent chatId = {currentChatDetails.id} chatHeight = {chatHeight}/>
            </div>
            <TextareaInput 
                sendMessageHandler = {sendMessageHandler}   
            />
            {showMenu.id !== null ? <div className = 'messagesOverlay' onClick={() => setShowMenu({id:null, value:false})}></div>: null}
        </div>
    </div>
}

export default inject(
    "chatsModel",
    "authModel",
    "serverAPImodel",
    "opportunitiesModel",
    "userModel"
)(observer(MessagesData))