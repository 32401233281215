import React, { useEffect, useState, useLayoutEffect} from "react"
import { observer, inject } from "mobx-react"
import ImgBuilder from "../../res/img/imgBuilder"


const ChatButton = (props) =>{
    const {
        company, 
        handleShowMessageModal,
        handleShowMessagePreview,
        chatsWithUnreadedState,

        chatsModel : {
            chatsList,
            chatsWithUnreadedMessages
        },
        authModel:{
            tableSizeParams
        },
        userModel : {
            userListIds
        },
    } = props
    let beforeformating = chatsList.filter(chat => chat.opportunity_id == company?.id)
    let filteredChat = beforeformating && JSON.parse(JSON.stringify(beforeformating))

    useEffect(() => {
    }, [chatsWithUnreadedMessages])
    

    return <>{ tableSizeParams.width < 961 ? 
        <button variant="primary" onClick={() => handleShowMessageModal(company.id)} className="modalPatternOpenButton">
            <img src = {(!chatsWithUnreadedMessages.includes(filteredChat[0]?.id) || !chatsWithUnreadedState.includes(filteredChat[0]?.id)) ? ImgBuilder.messageIcon :ImgBuilder.messageIconActive}/>
        </button> :
        <button onClick={() => handleShowMessagePreview(company.id)} className="messagesPreviewOpenButton">
            <img src = {(!chatsWithUnreadedMessages.includes(filteredChat[0]?.id) || !chatsWithUnreadedState.includes(filteredChat[0]?.id)) ? ImgBuilder.messageIcon :ImgBuilder.messageIconActive}/>
        </button>
    }</>
}

export default inject(
    "chatsModel",
    "authModel",
    "userModel"
)(observer(ChatButton))