import React, {useEffect, useState} from "react"
import DropdownInput from "../../components/Filters/DropdownInput"
import SearchFilter from "../../components/Filters/SearchFilter"
import ObjectiveDiagrams from "../../components/ObjectiveDiagrams"
import DealDashboardMainTable from "../../components/Tables/DealDashboardMainTable"
import ImgBuilder from "../../res/img/imgBuilder"
import EmptyPage from "../../components/EmptyPage"
import { dataObjective } from "../../mockedData/data"
import { userModel } from "../../models/user"
import { inject, observer } from "mobx-react"
import Loader from "../../components/Loader"
import useChatSocket from "../../sockets/chat"

import "./styles.scss"

const UserInfoPage = (props) =>{
    const {
        userModel:{userList, getUserList, setFilteredUser, filteredUser, setShowPreview, setNeedToRefresh},
        opportunitiesModel:{opportunitiesList, currentOppotunity, getOpportunitiesList},
        authModel:{auth_token, setAuthToken, getCurrentManeger},
        objectivesModel:{getObjectivesList},
        loadingModel:{loadingModelState, setLoadingModelState},
        chatsModel:{getChatsList}
    } = props
    const [currentCompany, setCurrentCompany] = useState(Object.keys(dataObjective)[0])
    const [currentUser, setCurrentUser] = useState(false)
    const [loaderState, setLoaderState] = useState(false)
    const [dataTopFilter, setDataTopFilter] = useState(userList.length ? 
        userList.map(item => {return {
            title: item.last_name + ", " + item.first_name, 
            id: item.id, 
            titleForSearch: item.last_name + " " + item.first_name + " " + item.first_name + " " +  item.last_name}}) :
        []
    )

    useEffect(() => {
        let token = localStorage.getItem("auth_token")
        setAuthToken(token)        
    }, [])

    useEffect(() => {
        if(!userList.length && auth_token.length){
            getUserList()
            getCurrentManeger()
            getChatsList()
        }
    }, [auth_token])

    /*useEffect(() => {
        !!localStorage.getItem("filteredUserId") && setFilteredUser(localStorage.getItem("filteredUserId").substring(1).slice(0, -1))
    }, [])
*/
    useEffect(()=>{
        if(userList.length){
            let formatedUserList = userList.map(item => {return {
                title: item.last_name + ", " + item.first_name, 
                id: item.id, 
                titlesForSearch: [item.last_name + " " + item.first_name, item.first_name + " " +  item.last_name]}})
            setDataTopFilter(formatedUserList)
        }      
    },[userList])

    const searchFilterResultSet = (item) =>{
        localStorage.getItem("currentOppotunityId") && localStorage.removeItem("currentOppotunityId")
        item && setFilteredUser(item)
        localStorage.setItem("forcastType", "All")
        setShowPreview(false)
    }

    const handleRefresh = () =>{
        setLoadingModelState(true)
        getOpportunitiesList(filteredUser.id);
        getObjectivesList(filteredUser.id);
        setFilteredUser(filteredUser);
        getUserList()
        setTimeout(() =>{setFilteredUser(filteredUser)},500)
        setTimeout(() =>{getUserList()},500)
        setTimeout(() => {setLoadingModelState(false)}, 2000)
        setNeedToRefresh(false)
    }

    return <div className = "containerUserInfoPage">
        <div className = "p-3 topFilter">
            <SearchFilter 
                data = {dataTopFilter} 
                dropDownIcon = {ImgBuilder.personIcon} 
                placeholder = "User Name"
                setCurrentItem = {searchFilterResultSet}
                currentItem = {filteredUser}
            />
            <div className="refreshButton" onClick={() => handleRefresh()}> 
                <img src = {ImgBuilder.refreshIcon}/>
                <span>Refresh</span>
            </div>
        </div>
        {opportunitiesList?.length ? <>
            <ObjectiveDiagrams data = {{}} currentCompany = {""}/>
            <div className = "mt-5" style = {{overflowX:"auto"}}>
                <DealDashboardMainTable setCurrentCompany = {setCurrentCompany}/>
            </div>
        </> :
            <EmptyPage/>
        }
        <Loader/>
    </div>
}

export default inject(
    "userModel",
    "opportunitiesModel",
    "authModel",
    "objectivesModel",
    "loadingModel",
    "chatsModel",
)(observer(UserInfoPage))