function urlBase64ToUint8Array(base64String) {
    let padding = '='.repeat((4 - base64String.length % 4) % 4);
    let base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    let rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export default function swDev() {
    const swUrl =  `${process.env.PUBLIC_URL}/sw.js`
    navigator.serviceWorker.register(swUrl)
    .then(function (registration) {
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            'BCeEvg0rc2BCfU26XUqxMs1JCV53i6rGSomvNQO0Y4872Fky-amRSy18OxWGue_cPHZGEl5GrTDOtr2_1-cQoO4',
          ),
        };
  
        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then(function (pushSubscription) {
        return pushSubscription;
      });
}

export async function askPermission() {
    const permissionResult_1 = await Notification.requestPermission()
    if (permissionResult_1 !== 'granted') {
        throw new Error("We weren't granted permission.");
    }
}