import { useState, useEffect } from "react";

function useAllChatSocket(socketUrl, socketOpenedState, setSocketOpenedState) {
  let [socket, setSocket] = useState(null);

  useEffect(() => {
    let socketOpenedStatus = JSON.stringify(localStorage.getItem("genSocketOpened"));
    let formatedSocketStatus = socketOpenedStatus.substring(1, socketOpenedStatus.length - 1);
    if(!socketOpenedState){
      _init();
    }
  }, []);

  useEffect(() => {
    if(socket){
      _connect();
      _enableReconnect();
      _handleError();
    }
  }, [socket])

  const _init = () => {
    const ws = `${socketUrl}?auth_token=${window.localStorage.getItem("auth_token")}`;
    socket = new WebSocket(ws);
    setSocket(socket);
  };

  const _connect = () => { 
    socket.onopen = function () {
      localStorage.setItem("genSocketOpened", true)
      //setSocketOpenedState(true)
      socket.send(
        JSON.stringify({
          command: "subscribe",
          identifier: JSON.stringify({ channel: "ManagerChannel" }),
        })
      );
    }
  }

  const _enableReconnect = () => {
    socket.onclose = function(e) {
      localStorage.setItem("genSocketOpened", false)
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function() {
        _connect()
      }, 400);
    }
  }

  const _handleError = () => {
    socket.onerror = function(err) {
      localStorage.setItem("genSocketOpened", false)
      console.error('Socket encountered error: ', err.message, 'Closing socket');
      socket.close();
    };
  }

 

  return socket;
}

export default useAllChatSocket;
