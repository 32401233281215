import { makeAutoObservable } from "mobx";

export class LoadingModel{
    constructor(){
        this.loadingModelState = false
        makeAutoObservable(this)
    }
    setLoadingModelState = newState =>{
        this.loadingModelState = newState
    }
}

export const loadingModel = new LoadingModel()