import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import './App.css';
import MainRouter from './router/MainRouter';
import useAllChatSocket from './sockets/allChats';
import mainlogo from './res/mainlogo.png';
import { useNavigate } from 'react-router-dom';

function App(props) {
  const {
    opportunitiesModel: {
      setCheckPushState,
  },
    chatsModel : {
        setClickNotificationUserName
    },

    serverAPImodel:{baseAPI}
  } = props
  let wsLink = ""
  switch(baseAPI){
      case ("https://spotlogicunit4.pp.ua/api/v1/"):
          wsLink = "wss://spotlogicunit4.pp.ua/cable/"
          break
      case ("https://spotlogicqaserver.pp.ua/api/v1/"):
          wsLink = "wss://spotlogicqaserver.pp.ua/cable/"
          break
      case ("https://xerox.pp.ua/api/v1/"):
          wsLink = "wss://xerox.pp.ua/cable/"
          break
      case ("https://spotlogic-mlogica.pp.ua/api/v1/"):
        wsLink = "wss://spotlogic-mlogica.pp.ua/cable/"
        break
      case ("https://netappspotlogic.pp.ua/api/v1/"):
          wsLink = "wss://netappspotlogic.pp.ua/cable/"
          break
      case ("https://spotlogic-matterport.pp.ua/api/v1/"):
          wsLink = "wss://spotlogic-matterport.pp.ua/cable/"
          break
      case ("https://spotlogic-solrenewables.pp.ua/api/v1/"):
          wsLink = "wss://spotlogic-solrenewables.pp.ua/cable/"
          break
      case ("https://spotlogic-scholarpath.pp.ua/api/v1/"):
          wsLink = "wss://spotlogic-scholarpath.pp.ua/cable/"
          break
      default: wsLink = ''
  }
  const socket = useAllChatSocket(wsLink, false, ()=>{});
  const navigate = useNavigate();

  function shunno_push_api(msg) {
    if(msg.chat_id !== localStorage.getItem("socketOpened")){  
      if (!Notification) {
        alert('not Notification!'); 
        return;
      }

      const func = (name, msg, maxLength) => {
        let result = name
        let msgArray = msg.split(" ")
        msgArray.filter(word => {
            let resultDraft = result + ' ' + word
            resultDraft.length < maxLength && (result = resultDraft)
        })
        result += " ..."
        return result
    }
      const dt = new Date(msg.created_at).toLocaleString().slice(12, 17);
      const options = {
          body: `${msg.message.length > 90 ? func(msg.username + " :", msg.message, 90) : msg.username + " :" + " " + msg.message}`,
          icon: mainlogo,
      }

      // for permission
      if (Notification.permission !== "granted") {
          Notification.requestPermission();
      }
      const notification = new Notification(msg.company_name, options);
    
      notification.onclick = function (e) {
        if (window.location.pathname !== '/') {
          navigate('/');
        }
        if (window.location.origin){
          window.focus();
        }else {
          window.open(window.location.origin, "_blank");
        }
        setClickNotificationUserName(msg);
        setCheckPushState(true);
      };
    }
}

const parseSocketMessage = (message) => {
    const data = JSON.parse(message.data);
    if(data.type === "ping"){
        return;
    }else if(data?.message?.message){  
        shunno_push_api(data?.message);
    }
}
      if(socket){
        socket.onmessage = (message) => {
            parseSocketMessage(message);
        }
    }
  return MainRouter
}

export default inject(
  "opportunitiesModel",
  "objectivesModel",
  "chatsModel",
  "authModel",
  "serverAPImodel",
  "userModel"
)(observer(App))
