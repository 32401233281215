import { useState, useEffect } from "react";

function useQuestionsSocket(socketUrl) {
  let [socket, setSocket] = useState(null);
  useEffect(() => {
      _init(); 
  }, []);

  useEffect(() => {
    if(socket){
      _connect();
      _enableReconnect();
      _handleError();
    }
  }, [socket])

  const _init = () => {
    const ws = `${socketUrl}?auth_token=${window.localStorage.getItem("auth_token")}`;
    socket = new WebSocket(ws);
    setSocket(socket);
  };

  const _connect = () => { 
    socket.onopen = function () {
      socket.send(
        JSON.stringify({
          command: "subscribe",
          identifier: JSON.stringify({ channel: "QuestionChannel" }),
        })
      );
    }
  }

  const _enableReconnect = () => {
    socket.onclose = function(e) {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function() {
        _connect()
      }, 400);
    }
  }

  const _handleError = () => {
    socket.onerror = function(err) {
      console.error('Socket encountered error: ', err.message, 'Closing socket');
      socket.close();
    };
  }

 

  return socket;
}

export default useQuestionsSocket;
