import { useState, useEffect } from "react";

function useChatSocket(socketUrl, socketOpenedState, setSocketOpenedState, socketOpenedList, setSocketOpenedList, chat_id) {
  let [socket, setSocket] = useState(null);
  useEffect(() => {
    let socketOpenedStatus = JSON.stringify(localStorage.getItem("socketOpened"));
    let formatedSocketStatus = socketOpenedStatus.substring(1, socketOpenedStatus.length - 1);
    if(true){
      _init(); 
    }
  }, [chat_id]);

  useEffect(() => {
    if(socket){
      _connect();
      _enableReconnect();
      _handleError();
    }
  }, [socket])

  const _init = () => {
    const ws = `${socketUrl}?auth_token=${window.localStorage.getItem("auth_token")}&chat_id=${chat_id}`;
    socket = new WebSocket(ws);
    setSocket(socket);
  };

  const _connect = () => { 
    socket.onopen = function () {
      localStorage.setItem("socketOpened", chat_id)
      let openedSockets = socketOpenedList
      openedSockets.length ? (!openedSockets.includes(chat_id) && openedSockets.push(chat_id)) : openedSockets.push(chat_id)
      setSocketOpenedList(openedSockets)
      setSocketOpenedState(true)
      socket.send(
        JSON.stringify({
          command: "subscribe",
          identifier: JSON.stringify({ channel: "ChatChannel" }),
        })
      );
    }
  }

  const _enableReconnect = () => {
    socket.onclose = function(e) {
      localStorage.setItem("socketOpened", false)
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
      setTimeout(function() {
        _connect()
      }, 400);
    }
  }

  const _handleError = () => {
    socket.onerror = function(err) {
      localStorage.setItem("socketOpened", false)
      console.error('Socket encountered error: ', err.message, 'Closing socket');
      socket.close();
    };
  }

 

  return socket;
}

export default useChatSocket;
