import React, { useEffect, useState } from "react"
import "../../res/consts/global_styles.scss"
import Header from "../../components/Header"
import { observer, inject } from "mobx-react"
import { useParams } from "react-router";
import { askPermission } from '../../serviceWorker';

import "./styles.scss"

const BasicPage = (props) =>{
const authToken = localStorage.getItem("auth_token");

    const {
        children, 
        authModel:{tableSizeParams}
    } = props
    let {id, companyName} = useParams()

    const [customStyles, setCustomStyles] = useState({})

    useEffect(() => {
        if(authToken) {
            //alert(authToken)
            askPermission()
        }
    },[])

    useEffect(() => {
        let stylesObject = {}
        if(tableSizeParams?.height && !companyName){
            stylesObject.minHeight = `${tableSizeParams.height + 492}px`
            stylesObject.height = `${window.innerHeight - 99}px`
        }else{
            stylesObject.minHeight = "auto"
        }   
        setCustomStyles(stylesObject)
    }, [tableSizeParams, companyName])

    return <>
        <Header/>
        <div style={customStyles} className = "basicPageContainer mt-3">
            <div className = "p-3 basicPageContainerShadow">
                {children}    
            </div>
        </div>

    </>
}

export default inject(
    "authModel"
)(observer(BasicPage))