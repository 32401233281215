import React, {useState, useRef, useEffect, useReducer} from 'react'
import { observer, inject } from "mobx-react"

import "./styles.scss"

const TypingComponent = (props) =>{
    const {
        chatId,
        chatHeight,
        chatsModel:{
            isTyping
        },
    } = props

useEffect(()=>{
    //console.log("chatHeight", chatHeight)
},[chatHeight])

return (isTyping.typingStatus && chatId === isTyping.chatId) ? 
    <div className="messagesConteinerTyping" style = {{marginTop: `${chatHeight - 35}px`}}>
        <span>Typing</span>
        <div id="typingDotsWave">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
        </div>
    </div> : null
}

export default inject(
    "chatsModel"
)(observer(TypingComponent))