import React, {useEffect, useState, useReducer} from "react"
import ImgBuilder from "../../res/img/imgBuilder"
import { inject, observer } from "mobx-react"
import "./styles.scss"


const CardCarousel = (props) => {
    const {
        cardWidth,
        data,
        alignment = "start",
        objectiveDetailsFirstSlide = 0,
        type,
        functionOnSliding,
        filteredObjectivesList,
        opportunitiesModel:{
            currentOppotunity
        }
    } = props
    const screenWidth = window.innerWidth;
    const [cardsPerSlide, setCardsPerSlide] = useState((screenWidth > 1270) ? 5 : (screenWidth >= 768 && screenWidth < 1270) ? 2 : 1);
    const [transform, setTransform] = useState(0)
    //count of ID from 1
    const [lastItemId, setLastItemId] = useState(data.length > cardsPerSlide ? cardsPerSlide : data.length)
    const [dataLength, setDataLength] = useState(data.length)
    const carouselwidth = cardWidth*cardsPerSlide
    const [wideScreen, setWideScreen] = useState(screenWidth > 1270 ? true : false);

    const resizeHandler = () => {
        const screenWidth = window.innerWidth;
        if(type === "objectiveDetails") {
            setCardsPerSlide(1);
            return;
        };

        if(screenWidth >= 1270){
            setCardsPerSlide(5)
            setTransform(0)
            setLastItemId(5)
            setWideScreen(true);
        } else if( screenWidth > 768 && screenWidth < 1270){
            setCardsPerSlide(2)
            setTransform(0)
            setLastItemId(2)
            setWideScreen(false);
        } else {
            setCardsPerSlide(1)
            setTransform(0)
            setLastItemId(1)
            setWideScreen(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [cardsPerSlide, screenWidth]);

    useEffect(()=>{
        if(objectiveDetailsFirstSlide){
            setLastItemId(objectiveDetailsFirstSlide)
            setTransform(-(objectiveDetailsFirstSlide-1)*cardWidth)
        }else if(type !== "objectiveDetails"){
            setLastItemId(objectiveDetailsFirstSlide)
            setTransform(0)
        }
    },[objectiveDetailsFirstSlide, currentOppotunity])

    const handleSwipeNarrowScreen = (side) =>{
        if(side === "right"){
            setTransform(transform - cardWidth)

            type === "objectiveDetails" &&
            functionOnSliding(filteredObjectivesList[parseInt(lastItemId)])

            setLastItemId(parseInt(lastItemId) + 1)

        }else{
            setTransform(transform + cardWidth)

            type === "objectiveDetails" &&
            functionOnSliding(filteredObjectivesList[parseInt(lastItemId) - 2])

            setLastItemId(parseInt(lastItemId) - 1)
        }
    }



    const handleSwipeWideScreen = (side) =>{
        if(side === "right"){
            const hideCardsCounter = dataLength - lastItemId;
            if(hideCardsCounter >= 5 ) {
                setTransform(prevTransform => prevTransform - 5*cardWidth);
                type === "objectiveDetails" &&
                functionOnSliding(filteredObjectivesList[parseInt(lastItemId)])
                setLastItemId(parseInt(lastItemId) + 5)
            } else {
                setTransform(prevTransform => prevTransform - hideCardsCounter*cardWidth);
                type === "objectiveDetails" &&
                functionOnSliding(filteredObjectivesList[parseInt(lastItemId)])
                setLastItemId(parseInt(lastItemId) + hideCardsCounter)
            }

        }else{
            const hideCardsCounter = lastItemId - 5;
            if(hideCardsCounter >= 5 ) {
                setTransform(prevTransform => prevTransform + 5*cardWidth)
                type === "objectiveDetails" &&
                functionOnSliding(filteredObjectivesList[parseInt(lastItemId) - 2])
                setLastItemId(parseInt(lastItemId) - 5)
            } else {
                setTransform(prevTransform => prevTransform + hideCardsCounter*cardWidth)
                type === "objectiveDetails" &&
                functionOnSliding(filteredObjectivesList[parseInt(lastItemId) - 2])
                setLastItemId(parseInt(lastItemId) - hideCardsCounter)
            }
        }
    }

    useEffect(() =>{
        if(data){
            if(type !== "objectiveDetails"){setLastItemId(data.length > cardsPerSlide ? cardsPerSlide : data.length); setDataLength(data.length)}
            (data.length !== dataLength) && setDataLength(data.length)
        }
    }, [data, currentOppotunity])

    return <div className = "containerCardCarousel" style = {{width : `${carouselwidth}px`}}>
        <div className = "arrowsCardCarousel">
            {(lastItemId - cardsPerSlide > 0 && (dataLength ? true : null)) &&
                <img src = {ImgBuilder.arrowLeft}
                     className = "arrowLeftCardCarousel"
                     onClick = {() => wideScreen && type !== 'objectiveDetails' ? handleSwipeWideScreen("left") : handleSwipeNarrowScreen("left")}
                />
            }
            {((dataLength ? true : null) && lastItemId < dataLength) &&
                <img src = {ImgBuilder.arrowRight}
                     className = "arrowRightCardCarousel"
                     onClick = {() => wideScreen && type !== 'objectiveDetails' ? handleSwipeWideScreen("right") : handleSwipeNarrowScreen("right")}
                />
            }
        </div>
        <div className = "overflowCardCarousel">
            <div className = "carouselCardCarousel" style = {{
                transform:`translateX(${transform}px)`,
                justifyContent:`${alignment === "start" ? "flex-start" : (data.length > cardsPerSlide ? "flex-start" : "flex-end")}`
            }}>
                {data}
            </div>
        </div>
    </div>
}

export default inject(
    "opportunitiesModel",
    "userModel"
)(observer(CardCarousel))