import * as yup from 'yup'

const ForgotpasswordSchema = yup.object().shape({
    email: yup.string()
        .min(6, 'Incorrect email')
        .max(48, 'Incorrect email')
        .email('Incorrect email')
        .matches(
            /^([A-Za-z@./,/?/!/\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/g,
                'Email can only contain Latin letters.'
            )
        .required('Required'),
});

export default ForgotpasswordSchema