import React, { useEffect } from "react"
import useUpdateSocket from "../../sockets/userChannel";
import useQuestionsSocket from "../../sockets/questionChannel";
import { observer, inject } from "mobx-react";
import ImgBuilder from "../../res/img/imgBuilder";

import "./styles.scss"

const NeedToRefreshAlert = (props) => {
    const {
        page = "normal",
        serverAPImodel:{baseAPI},
        userModel : {
            filteredUser,
            needToRefresh,
            setNeedToRefresh,
        },
        objectivesModel:{
            currentObjective,
            checkIfUpdatedQuestionCritical,
            objectivesList,
            objectivesWithCriticalQuestions
        },
        opportunitiesModel:{
            checkIfUpdatedDisplayedFields,
            opportunitiesList
        }
    } = props

    let wsLink = ""
    switch(baseAPI){
        case ("https://spotlogicunit4.pp.ua/api/v1/"):
            wsLink = "wss://spotlogicunit4.pp.ua/cable/"
            break
        case ("https://spotlogicqaserver.pp.ua/api/v1/"):
            wsLink = "wss://spotlogicqaserver.pp.ua/cable/"
            break
        case ("https://xerox.pp.ua/api/v1/"):
            wsLink = "wss://xerox.pp.ua/cable/"
            break
        case ("https://spotlogic-mlogica.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-mlogica.pp.ua/cable/"
            break
        case ("https://netappspotlogic.pp.ua/api/v1/"):
            wsLink = "wss://netappspotlogic.pp.ua/cable/"
            break
        case ("https://spotlogic-matterport.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-matterport.pp.ua/cable/"
            break
        case ("https://spotlogic-solrenewables.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-solrenewables.pp.ua/cable/"
            break
        case ("https://spotlogic-scholarpath.pp.ua/api/v1/"):
            wsLink = "wss://spotlogic-scholarpath.pp.ua/cable/"
            break
    }

    const updatesSocket = useUpdateSocket(wsLink, filteredUser.id);

    /*useEffect(() => {
        checkIfUpdatedQuestionCritical(filteredUser.id, "fa24b0c0-6682-4569-84eb-d0e5bb5025c0")
    }, [])*/

    const parseUpdatesSocketMessage = message => {
        const data = JSON.parse(message.data);
        if(["ping","welcome","confirm_subscription"].includes(data.type)){
            return;
        }else if(["ActionItem", "Item", "Category", "Company", "Case", "Product"].includes(data?.message?.model)){
            return
        }else if(data?.message?.model === "Opportunity"){
            if(["create"].includes(data?.message?.event)){
                setNeedToRefresh(true)
                return;
            }else if(["delete", "destroy"].includes(data?.message?.event)){
                let opportunitiesListIds = opportunitiesList.map(opp => opp.id)
                if(opportunitiesListIds.includes(data?.message?.id)){
                    setNeedToRefresh(true)
                    return
                } else {
                    return
                }
            }
            checkIfUpdatedDisplayedFields(filteredUser.id, data?.message?.id, data?.message?.event);
        }else if(["Objective"].includes(data?.message?.model)){
            if(["create"].includes(data?.message?.event)) {
                return;
            }
            let objectivesListIds = objectivesList.map(objective => objective.id)
            if(["update", "destroy", "delete"].includes(data?.message?.event) && !objectivesListIds.includes(data?.message?.id)){
                return
            }else if(!objectivesWithCriticalQuestions.includes(data?.message?.id) && !["create"].includes(data?.message?.event)){
                return
            }
            checkIfUpdatedQuestionCritical(filteredUser.id, data?.message?.id);
        }else if(["Question", "Answer"].includes(data?.message?.model)){
            checkIfUpdatedQuestionCritical(filteredUser.id, data?.message?.objective_id);
        }else if(["Topic"].includes(data?.message?.model)){
            return;
        }else{
            setNeedToRefresh(true)
        }
    }

    useEffect(() => {
        if(updatesSocket){
            updatesSocket.onmessage = message => {
                !needToRefresh && parseUpdatesSocketMessage(message)
            }
        }
        
    },[updatesSocket])

    const questionSocket = useQuestionsSocket(wsLink);

    useEffect(() => {
        if(questionSocket){
            questionSocket.onmessage = message => {
                parseUpdatesSocketMessage(message)
            }
        }
    },[questionSocket])

    useEffect(() => {
        return  (() => {updatesSocket && updatesSocket.close(); setNeedToRefresh(false)})()
    }, [filteredUser?.id])

    useEffect(() => () => updatesSocket && updatesSocket.close(), [updatesSocket])
    useEffect(() => () => questionSocket && questionSocket.close(), [questionSocket])
    return needToRefresh ? 
        <>
            <div className="needToRefreshModal">
                <img src = {ImgBuilder.whiteTriangularIcon}/>
                <span>Selected user made some changes. Please, refresh the page to see the updates. </span>
            </div> 
            <div className="needToRefreshOverlay"/>
        </>: null
}

export default inject(
    "opportunitiesModel",
    "objectivesModel",
    "chatsModel",
    "authModel",
    "serverAPImodel",
    "userModel"
)(observer(NeedToRefreshAlert))