import { observable, makeAutoObservable , action, toJS } from 'mobx'
import axios from 'axios'
import { authModel } from './auth'
import { baseAPIAdmin } from '../res/consts/settings'
import { opportunitiesModel } from './opportunities'
import { objectivesModel } from './objectives'
import { forecastFilterCategories } from '../res/consts/filtersVariants'
import { serverAPImodel } from './serverAPI'
import { chatsModel } from './chats'

export class UserModel{
    constructor(){
        this.filteredUser = {}
        this.userList = []
        this.userListIds = []
        this.showPreview = false
        this.needToRefresh = false
        makeAutoObservable(this)
    }

    setNeedToRefresh = (data) => {
        this.needToRefresh = data
    }

    setShowPreview = (data) => {
        this.showPreview = data
    }

    setCurrentList = userList =>{
        this.userList = userList.users
        this.userListIds = userList.users.map(usr => usr.id)
    }

    setFilteredUser = filteredUser => {
        if(filteredUser.id !== "unset"){ 
            this.filteredUser = filteredUser
            objectivesModel.getObjectivesList(filteredUser.id)
            filteredUser.id && localStorage.setItem("filteredUserId", JSON.stringify(filteredUser.id))
        }else{
            opportunitiesModel.setOpportunitiesList([])
            objectivesModel.setCurrentObjective({})
            localStorage.setItem("filteredUserId", JSON.stringify(""))
        }
    }

    getUserList = () => {
        const setCurrentList = this.setCurrentList
        let token = authModel.auth_token
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": token
            },
            params:{
                per_page: 10000
            }
        };
        axios.get(`${serverAPImodel.baseAPIAdmin}users`, headers)
        .then(function (response) {
            setCurrentList(response.data);
        })
        .catch(function (error) {
            console.log('error', error);
        })
    }
}

export const userModel = new UserModel()