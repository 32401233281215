import React, {useRef, useEffect, useState} from "react"
import ImgBuilder from "../../res/img/imgBuilder"
import { useFormik, Field, Formik, Form, ErrorMessage } from "formik"
import SimpleInput from "../../components/FormFields/SimleInput"
import GoBackButton from "../../components/GoBackButton"
import * as yup from 'yup'
import LoginSchema from "../../res/validationSchemes/login"
import ForgotpasswordSchema from "../../res/validationSchemes/forgotpassword"
import { useNavigate } from "react-router"
import { mailLink } from "../../res/consts/router_urls_const"
import { inject, observer } from "mobx-react"
import DropdownInput from "../../components/Filters/DropdownInput"

import "./styles.scss"

const LoginPage = (props) => {
    const {
        authModel:{
            signIn,
            serverErrors,
            setErrorsEmpty
        },
        serverAPImodel:{
            setBaseAPI,
            baseAPI,
            baseAPILogin
        }
    } = props

    const [formWidth, setFormWidth] = useState(0)
    const [formHeight, setFormHeight] = useState(0)
    const [serverName, setServerName] = useState(1)
    const [typeOfFields, setTypeOfFields] = useState("login")
    const [initValuesForm, setInitValuesForm] = useState({
        email: '',
        password:'',
    })
    
    const [validationSchema, setValidationSchema] = useState(LoginSchema)
    const [serverErrorsState, setServerErrorsState] = useState({email:"", password:""})

    const navigate = useNavigate()

    const formRef = useRef(null)

    useEffect(()=>{
        if(formRef){
            setFormWidth(formRef.current.offsetWidth)
            setFormHeight(formRef.current.offsetHeight)
        }
    },[formRef])

    useEffect(() => {
        if(typeOfFields === "login"){
            setInitValuesForm({
                email: '',
                password:'',
            })
            setValidationSchema(LoginSchema)
        }else if(typeOfFields === "forgotPassword"){
            setInitValuesForm({
                email: ''
            })
            setValidationSchema(ForgotpasswordSchema)
        }

    },[typeOfFields])

    useEffect(() => {
        setServerErrorsState(JSON.parse(JSON.stringify(serverErrors)))
    }, [serverErrors.password, serverErrors.email])

    const submitlogin = (values) =>{
        if(typeOfFields === "login"){
            signIn(values)
        }else if(typeOfFields === "forgotPassword"){
            delete values.password
            alert(JSON.stringify(values))
        }
    }

    const handleSecretInput = (e) =>{
        if(e.target.value.length > 1){
            if((e.target.value.match(/1/g) || []).length > (e.target.value.match(/2/g) || []).length){
                setServerName(1)
                setBaseAPI(1)
            }else{
                setServerName(2)
                setBaseAPI(2)
            }
        } 
    }

    const setServerWrapper = (item) =>{
        setServerName(item.number)
        setBaseAPI(item.number)
    }
    
    return <div className = "pageContainerLoginPage">
    
        <div className = "containerLoginPage align-items-center" style = {{
            boxShadow: `${serverName === 2 ? "0px 2px 10px hsla(210, 99%, 71%, 0.5)" : "0px 2px 10px rgba(186, 188, 201, 0.5)"}`
            }}>
            <div className = "leftsideContainerLoginPage" ref = {formRef}>
                <input className="oneSimpleInput" onChange = {(e) => handleSecretInput(e)}/>
                {typeOfFields === "login" ? <div className = "titleLoginPage">
                    <div className = "textTitleLoginPage">welcome to</div>
                    <img src = {ImgBuilder.mainlogoTM}/>
                </div> : <div></div>}
                <Formik
                    initialValues= {initValuesForm}
                    onSubmit = {values => submitlogin(values)}
                    validationSchema = {validationSchema}
                >         
                {typeOfFields === "login" ?
                    ({ errors, touched }) => (
                        <Form>
                            <SimpleInput
                                type = "email"
                                label = "Email"
                                width = {300}
                                required = {true}
                                name = "email"
                                serverError = {serverErrorsState.email}
                                setErrorsEmpty = {setErrorsEmpty}
                                maxlength="48"
                            />
                            <SimpleInput
                                type = "password"
                                label = "Password"
                                width = {300}
                                required = {true}
                                name = "password"
                                serverError = {serverErrorsState.password}
                                setErrorsEmpty = {setErrorsEmpty}
                                maxlength="48"
                            />
                            <div className = "containerSimpleInput" style = {{width:"300px"}}>
                                <label>Select Server
                                    <span className = "starSimpleInput">*</span>
                                </label>
                                <DropdownInput
                                    data = {[
                                        {title: "DEMO", number:1},
                                        {title: "SpotlogicQA", number:2},
                                        {title: "Xerox", number:3},
                                        {title: "WWT", number:4},
                                        {title: "NetApp", number:5},
                                        {title: "Matterport", number:6},
                                        {title: "SolRenewables", number:7},
                                        {title: "ScholarPath", number:8}
                                    ]}
                                    type = "server"
                                    setCurrentItem = {(item) => setServerWrapper(item)}
                                />
                            </div>
                            <div className = "actionsLoginPage">
                                <button type="submit">Login</button>
                                <div className = "forgotpasswordLoginPage" onClick = {() => setTypeOfFields("forgotPassword")}>Forgot password?</div>
                            </div>
                            <a href = "mailto:support@spotlogic.com" className="supportMail">support@spotlogic.com</a>
                        </Form>):
                        <Form>
                            <div className = "actionsLoginPage">
                                <GoBackButton text = "← Back to Login" goBackFunction = {setTypeOfFields}/>
                            </div>
                            <div className = "titleForgotPasswordPage">Forgot your password?</div>
                            <div className = "subTitleForgotPasswordPage">
                                Enter the email you used to sign in earlier. We will send you a link to reset your password.
                            </div>
                            <SimpleInput type = "email" label = "Email" width = {300} required = {true} name = "email"/>
                            <div className = "actionsLoginPage">
                                <button type="submit">Send Reset Link</button>
                            </div>
                        </Form>          
                        }
                </Formik>
            </div>
            <div className = "rightsideContainerLoginPage" style = {{
                background: `url(${ImgBuilder.loginImage})`, 
                width:`${formWidth}px`,
                height:`${formHeight}px`
            }}>
            </div>
        </div>
        
    </div>
}

export default inject(
    "authModel",
    "serverAPImodel"
)(observer(LoginPage))