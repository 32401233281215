import { makeAutoObservable } from "mobx"

export class ServerAPImodel{
    constructor(){
        this.baseAPI = localStorage.getItem("baseAPI") ? 
            (localStorage.getItem("auth_token") ? localStorage.getItem("baseAPI") : this.setInStorage("https://spotlogicunit4.pp.ua/api/v1/")) :
            this.setInStorage("https://spotlogicunit4.pp.ua/api/v1/")
        this.baseAPIAdmin = this.baseAPI + "admin/"
        this.baseAPILogin = this.baseAPI + "sessions/"
        this.baseAPIManagerInfo = this.baseAPI + "users/my_profile"
        this.socketUrl = ""
        this.socketUrl = ""
        makeAutoObservable(this)
    }
    setBaseAPI = serverInd =>{
        if(serverInd === 1){  
            this.baseAPI = "https://spotlogicunit4.pp.ua/api/v1/"
            localStorage.setItem("baseAPI", this.baseAPI)
            this.baseAPIAdmin = "https://spotlogicunit4.pp.ua/api/v1/admin/"
            this.baseAPILogin = "https://spotlogicunit4.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://spotlogicunit4.pp.ua/api/v1/users/my_profile"
        }else if(serverInd === 2){
            this.baseAPI = "https://spotlogicqaserver.pp.ua/api/v1/"
            localStorage.setItem("baseAPI", this.baseAPI)
            this.baseAPIAdmin = "https://spotlogicqaserver.pp.ua/api/v1/admin/"
            this.baseAPILogin = "https://spotlogicqaserver.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://spotlogicqaserver.pp.ua/api/v1/users/my_profile"
            this.socketUrl = "wss://spotlogicqaserver.pp.ua/cable"
        }else if(serverInd === 3){
            this.baseAPI = "https://xerox.pp.ua/api/v1/"
            localStorage.setItem("baseAPI", this.baseAPI)
            this.baseAPIAdmin = "https://xerox.pp.ua/api/v1/admin/"
            this.baseAPILogin = "https://xerox.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://xerox.pp.ua/api/v1/users/my_profile"
            this.socketUrl = "wss://xerox.pp.ua/cable"
        }
        else if(serverInd === 4){
            this.baseAPI = "https://spotlogic-mlogica.pp.ua/api/v1/"
            localStorage.setItem("baseAPI", this.baseAPI)
            this.baseAPIAdmin = "https://spotlogic-mlogica.pp.ua/api/v1/admin/"
            this.baseAPILogin = "https://spotlogic-mlogica.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://spotlogic-mlogica.pp.ua/api/v1/users/my_profile"
            this.socketUrl = "wss://spotlogic-mlogica.pp.ua/cable"
        }
        else if(serverInd === 5){
            this.baseAPI = "https://netappspotlogic.pp.ua/api/v1/"
            localStorage.setItem("baseAPI", this.baseAPI)
            this.baseAPIAdmin = "https://netappspotlogic.pp.ua/api/v1/admin/"
            this.baseAPILogin = "https://netappspotlogic.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://netappspotlogic.pp.ua/api/v1/users/my_profile"
            this.socketUrl = "wss://netappspotlogic.pp.ua/cable"
        }
        else if(serverInd === 6){
            this.baseAPI = "https://spotlogic-matterport.pp.ua/api/v1/"
            localStorage.setItem("baseAPI", this.baseAPI)
            this.baseAPIAdmin = "https://spotlogic-matterport.pp.ua/api/v1/admin/"
            this.baseAPILogin = "https://spotlogic-matterport.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://spotlogic-matterport.pp.ua/api/v1/users/my_profile"
            this.socketUrl = "wss://spotlogic-matterport.pp.ua/cable"
        }
        else if(serverInd === 7){
            this.baseAPI = "https://spotlogic-solrenewables.pp.ua/api/v1/"
            localStorage.setItem("baseAPI", this.baseAPI)
            this.baseAPIAdmin = "https://spotlogic-solrenewables.pp.ua/api/v1/admin/"
            this.baseAPILogin = "https://spotlogic-solrenewables.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://spotlogic-solrenewables.pp.ua/api/v1/users/my_profile"
            this.socketUrl = "wss://spotlogic-solrenewables.pp.ua/cable"
        }
        else if(serverInd === 8){
            this.baseAPI = "https://spotlogic-scholarpath.pp.ua/api/v1/"
            localStorage.setItem("baseAPI", this.baseAPI)
            this.baseAPIAdmin = "https://spotlogic-scholarpath.pp.ua/api/v1/admin/"
            this.baseAPILogin = "https://spotlogic-scholarpath.pp.ua/api/v1/sessions/"
            this.baseAPIManagerInfo = "https://spotlogic-scholarpath.pp.ua/api/v1/users/my_profile"
            this.socketUrl = "wss://spotlogic-scholarpath.pp.ua/cable"
        }
        
    }
    setInStorage = api =>{
        localStorage.setItem("baseAPI", api)
        return api
    }
}

export const serverAPImodel = new ServerAPImodel