import React, {useState, useEffect, useRef, useReducer} from "react"
import "./styles.scss"
import ImgBuilder from "../../../res/img/imgBuilder"
import { Dropdown } from "react-bootstrap"
import { inject, observer } from "mobx-react"
import { toJS } from "mobx"


const SearchFilter = (props) =>{
    const {
        data, 
        dropDownIcon = "", 
        type = "", 
        placeholder = false, 
        setCurrentItem = (item) => item,
        currentItem = null,
        chatsModel: {
            clickNotificationUserName
        }
    } = props
    const [activeitem, setActiveItem] = useState(placeholder ? false : listTitles[0])
    const inputRef = useRef(null);
    const [inFocus, setInFocus] = useState(false)
    const [listTitles, setListTitles] = useState(data.length ? 
        data.sort((a,b) =>{
            let fa = a.title.toLowerCase()
            let fb = b.title.toLowerCase()
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0; 
        }) : 
        ["Empty"])
    const [inputVal, setInputVal] = useState(null)
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(()=>{
        data.length && setListTitles(data)
    },[data])

    useEffect(() => {
        currentItem?.title && setInputVal(currentItem?.title)
    }, [currentItem])
   
    const handlechange = (event) =>{
        let inputValue = event.target.value
        setInputVal(inputValue)
        inputValue.length === 0 && setActiveItem({id:"unset"})
        let filteredListTitles = data.filter(item => item.titlesForSearch.some(title => title.toLowerCase().includes(inputValue.toLowerCase())))
        !filteredListTitles.length && (filteredListTitles = [{title:"Select user"}])
        setListTitles(filteredListTitles)
        setInFocus(true)
    }

    useEffect(() => {
        if(clickNotificationUserName.username && clickNotificationUserName !== "Select user"){
            setActiveItem({title: clickNotificationUserName.username, id: clickNotificationUserName.user_id})
            setInputVal(clickNotificationUserName.username);          
        }
    }, [clickNotificationUserName]);

    const setInputData = (item) =>{
        if(item !== "Select user"){
            setActiveItem(item)
            setInputVal(item.title)            
        }
    }

    useEffect(()=>{
        activeitem && setCurrentItem(activeitem)
    },[activeitem])

    return <div className = "containerDropdownInput">
        <div className = "dropdownDropdownInput">
            <Dropdown >
                <Dropdown.Toggle id="dropdown-basic" className = "p-2">
                    <div className = "personIconDropdownInput">
                        <img src = {dropDownIcon} style = {{width: "14px"}}/>
                    </div>
                    <input 
                        ref = {inputRef} 
                        type = "text" 
                        placeholder = {!inputVal && placeholder} 
                        className = "chosenItemTextDropdownInput"
                        onChange = {handlechange}
                        value = {inputVal}
                    />
                    <div>
                        <img src = {ImgBuilder.filterArrowDown} style = {{width: "10px"}}/>
                    </div>
                    
                </Dropdown.Toggle>
                <Dropdown.Menu className = "mt-0" style = {{display:`${inFocus ? "block" : ""}`}}>
                    {listTitles.length === 1 && listTitles[0].title === "Select user" ?
                        <div className="hint">{listTitles[0].title}</div>
                        : listTitles.map(item =>
                            <Dropdown.Item onClick = {() => {setInputData(item); setInFocus(false)}}>
                                {item.title}
                            </Dropdown.Item>
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
            <div className = "backgroundDropdownInput" 
                style = {{display:`${inFocus ? "block" : "none"}`}}
                onClick = {() => setInFocus(false)}
            ></div>
        </div>
    </div>
}

export default inject(
    "chatsModel"
)(observer(SearchFilter))