import React, {useEffect, useState} from "react"
import TotalDiagram from "./TotalDiagram"
import SingleObjectiveDiagram from "./SingleObjectiveDiagram"
import CardCarousel from "../CardCarousel"
import { objectiveDetails } from "../../res/consts/router_urls_const"
import { inject, observer } from "mobx-react"

import "./styles.scss"

const ObjectiveDiagrams = (props) =>{
    const {
        data, 
        currentCompany,
        objectivesModel:{
            objectivesList,
            setFilteredObjectivesList,
            setDiagramsData,
            diagramsData,
            buildDiagramsData
        },
        opportunitiesModel:{
            currentOppotunity,
            opportunitiesList,
            setCurrentOppotunity,
        }
    } = props

    const [diagramsDataState, setDiagramsDataState] = useState(diagramsData)
    
    useEffect(() => {     
        setDiagramsDataState(diagramsData)
    }, [diagramsData])

    useEffect(() => {
        objectivesList.length && buildDiagramsData({company: opportunitiesList[0]})
    }, [objectivesList, opportunitiesList])

    return <div className = "mt-2 p-4 containerObjectiveDiagrams">
            <TotalDiagram data = {diagramsDataState.length ? diagramsDataState : []}/>
            {diagramsDataState.length ? 
            <CardCarousel
                cardWidth = {150}
                alignment = {"end"}
                data = {diagramsDataState.map((objective, index) => 
                    <SingleObjectiveDiagram 
                        diagramTitle = {objective.title} 
                        totalQuestionCount = {objective.totalQuestionCount}
                        answeredQuestionCount = {objective.answeredQuestionCount}
                        detailsUrl = {objectiveDetails}
                        type = "mainPage"
                        index = {index + 1}
                        currentCompany = {currentOppotunity.company.id}
                        key = {index}
                    />
                )}
            />:<div></div>}
    </div>
}

export default inject(
    "objectivesModel",
    "opportunitiesModel"
)(observer(ObjectiveDiagrams));