export const questionsData = [
    {
        questionText: "Augue malesuada quam eu nam morbi cras ligula?",
        answers:false,
        color:false,   
    },
    {
        questionText: "Augue malesuada quam eu nam morbi cras ligula?",
        answers:false,
        color:false   
    },
    {
        questionText: "Augue malesuada quam eu nam morbi cras ligula?",
        answers:[
            {
                text: "Nibh id ultricies nisl pretium rutrum non. Tellus porta nibh sit sit. Non, fusce sociis nibh elit scelerisque sit.",
                date:"October 23, 2020 1:07pm",
                color:"green",
                name: "John Guevara"
            },
            {
                text: "Auctor integer massa senectus arcu consectetur facilisis vel. Id donec in nunc adipiscing vulputate quam. Fermentum mattis purus venenatis a at habitasse sollicitudin.",
                date:"October 23, 2020 1:07pm",
                color:"green",
                name: "John Guevara"
            }
        ]
    },
    {
        questionText: "Augue malesuada quam eu nam morbi cras ligula?",
        answers:[
            {
                text: "Nibh id ultricies nisl pretium rutrum non. Tellus porta nibh sit sit. Non, fusce sociis nibh elit scelerisque sit.",
                date:"October 23, 2020 1:07pm",
                color:"red",
                name: "John Guevara"
            },
            {
                text: "Auctor integer massa senectus arcu consectetur facilisis vel. Id donec in nunc adipiscing vulputate quam. Fermentum mattis purus venenatis a at habitasse sollicitudin.",
                date:"October 23, 2020 1:07pm",
                color:"red",
                name: "John Guevara"
            }
        ]
    },
]

export const dataObjective =
    {
        XEROX: [   
            {title: "Goal1", totalQuestionCount: 6, answeredQuestionCount:5}, 
            {title: "Goal2", totalQuestionCount: 6, answeredQuestionCount:5},
            {title: "Goal3", totalQuestionCount: 6, answeredQuestionCount:5},
            {title: "Goal4", totalQuestionCount: 6, answeredQuestionCount:5},
            {title: "Goal5", totalQuestionCount: 6, answeredQuestionCount:5},
            {title: "Goal6", totalQuestionCount: 6, answeredQuestionCount:5},
            {title: "Goal7", totalQuestionCount: 4, answeredQuestionCount:2},
        ],
   
        MyCompany:[
            {title: "Goal1", totalQuestionCount: 6, answeredQuestionCount:5}, 
            {title: "Goal2", totalQuestionCount: 6, answeredQuestionCount:5},
            {title: "Goal3", totalQuestionCount: 6, answeredQuestionCount:5},
        ]
    }

export const companies = [
    {
        name:"XEROX",
        opportunity: "Opportunity Name",
        case: "Use Case",
        theme: "Product",
        amount: 75000,
        date: "10/20/2021",
        relationship_strength: "high",
        urgency: "mid",
        strategic_business_impact: "low"
    },
    {
        name:"MyCompany",
        opportunity: "Opportunity Name",
        case: "Use Case",
        theme: "Product",
        amount: 75000,
        date: "10/20/2021",
        relationship_strength: "high",
        urgency: "mid",
        strategic_business_impact: "low"
    },
]