import React from "react";
import { useNavigate } from "react-router-dom"

import "./styles.scss"


const Breadcrumbs = ({chains, urls}) =>{
    const navigate = useNavigate()
    const handlebreadcrumbFilter = (index) => {
        urls.length > index &&
        navigate(urls[index])
    }
    return <nav aria-label="breadcrumb">
    <ol className = "breadcrumb">
        {chains.map((chain, index) => <li 
            className = {`breadcrumb-item ${index+1 < chains.length && "active"}`} 
            onClick = {() => handlebreadcrumbFilter(index)}
            key = {index}
        >
            {chain}
        </li>)}        
    </ol>
</nav>   
}

export default Breadcrumbs