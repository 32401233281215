import React, {useState, useEffect,useRef} from 'react'
import ImgBuilder from '../../res/img/imgBuilder'
import { observer, inject } from "mobx-react"

import "./styles.scss"

const TextareaInput = (props) => {
    const {
        sendMessageHandler,
        chatsModel : {
            currentChatDetails, 
            setTypingStatus
        },
        userModel:{
            needToRefresh
        }
    } = props
    const [chatInput, setChatInput] = useState("")
    const [rowsCount, updateRowsCount] = useState(0)
    const textAreaRef = useRef(null)

 
    
    const sendMessageHandlerWrapper = () =>{
        sendMessageHandler(chatInput)
        setChatInput("")
        textAreaRef.current.style.height = '35px'
    }

    const autoGrow = (element,defaultHeight) => {
        if(element) {
            const target = element.target ? element.target : element
            target.style.height = defaultHeight
            target.style.height = `${target.scrollHeight}px`
        }
    }
    useEffect(() => {
        let newRowsCount = chatInput.split('\n').length
        updateRowsCount(newRowsCount)
    },[chatInput])

    return <form onSubmit={(e) => e.preventDefault()} className = "textareaInputContainer">
        <textarea
            ref={textAreaRef}
            type = "text" 
            placeholder="Enter your message"
            value = {chatInput}
            onChange={element => {
                let val = element.target.value
                if(val && val[val.length - 1].charCodeAt() < 400 || !val[val.length - 1]){
                    setChatInput(val)
                    autoGrow(element,'30px')
                } 
            }}
            maxLength={rowsCount > 20 ? chatInput.length : "500"}
            rows = "3"
            onFocus={(e) => {
                setTypingStatus(currentChatDetails.id, true)
            }}
            onBlur = {() => setTypingStatus(currentChatDetails.id, false)}
            key = "textarea1"
            preventDefault = {true}
            disabled = {needToRefresh}
        />
        <button type="submit" onClick={sendMessageHandlerWrapper} disabled = {needToRefresh}>
            <img src = {ImgBuilder.sendMessageArrowIcon}/>
        </button>
    </form>
}

export default inject(
    "chatsModel",
    "authModel",
    "serverAPImodel",
    "userModel"
)(observer(TextareaInput))