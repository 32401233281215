import React, {useState, useRef, useEffect} from 'react'
import MessagesData from '../MessagesData'
import { observer, inject } from "mobx-react"
import ImgBuilder from '../../res/img/imgBuilder'
import mockedMessages from '../MessagesModal/mockedData'
import Draggable from 'react-draggable'
import MessagesPreviewInside from './MessagePreviewInside'

import "./styles.scss"

const MessagesPreview = (props) => {
    const {
        customStyles,
        handleCloseMessagePreview,
        openBigModal,
        setOpenBigModal,
        draggable = false,
        setOpenModalObject,
        opportunityId,
        openModalObject = {},
        opportunitiesModel: {currentOppotunity},
        chatsModel: {
            setCurrentChatMessagesDataEmpty, 
            setOpenedChat,
        },
        userModel: {
            filteredUser
        }
    } = props

    //const [inputVal, setInputVal] = useState("")
    const [myMessages, setMyMessages] = useState(mockedMessages)
    const [showMenu, setShowMenu] = useState({id:null, value:false})
    const [transparent, setTransparent] = useState(false)
    const [allowedFocus, setAllowedFocus] = useState(false)

    const containerRef = useRef(null)
    const handleClose = () =>{  
        setMyMessages(mockedMessages)
        setShowMenu({id:null, value:false})
        handleCloseMessagePreview()
        setCurrentChatMessagesDataEmpty()
    }
    
    useEffect(() => {
        localStorage.getItem("currentOppotunityId") && localStorage.getItem("currentOppotunityId").substring(1).slice(0, -1) !== opportunityId  && !openBigModal && handleCloseMessagePreview()
    }, [filteredUser])

    useEffect(() => {
        !openModalObject?.show && setTransparent(false)
        !openModalObject?.show && setOpenedChat(true)
    },[openModalObject])

    return <div style = {{opacity:`${transparent ? "0" : "1"}`}}>
            {draggable ?
                <Draggable
                    handle = ".messagesPreviewOverlayCursor"
                    bounds= "body"
                >
                    <div className='messagesPreviewContainer' style = {customStyles} ref = {containerRef}>
                        <MessagesPreviewInside
                            setOpenModalObject = {setOpenModalObject}
                            handleCloseMessagePreview = {handleCloseMessagePreview}
                            setTransparent = {setTransparent}
                            setOpenBigModal={setOpenBigModal}
                            handleClose = {handleClose}
                            draggable = {draggable}
                            myMessages = {myMessages}
                            showMenu = {showMenu}
                            setMyMessages = {setMyMessages}
                            setShowMenu = {setShowMenu}
                            opportunityId = {opportunityId}
                        />
                        <div className='messagesPreviewOverlay'>
                            <div className='messagesPreviewOverlayCursor'/>                        
                        </div>
                    </div>
                </Draggable>:
                <div className='messagesPreviewContainer' style = {customStyles} ref = {containerRef}>
                    <MessagesPreviewInside
                        setOpenModalObject = {setOpenModalObject}
                        handleCloseMessagePreview = {handleCloseMessagePreview}
                        setTransparent = {setTransparent}
                        handleClose = {handleClose}
                        draggable = {draggable}
                        myMessages = {myMessages}
                        showMenu = {showMenu}
                        setMyMessages = {setMyMessages}
                        setShowMenu = {setShowMenu}
                        opportunityId = {opportunityId}
                    />
                </div>
            }
        </div>
}

export default inject(
    "opportunitiesModel",
    "chatsModel",
    "userModel"
)(observer(MessagesPreview))