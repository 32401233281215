import React, { useEffect, useState } from "react"
import CardCarousel from "../../components/CardCarousel"
import SingleObjectiveDiagram from "../../components/ObjectiveDiagrams/SingleObjectiveDiagram"
import { useParams } from "react-router"
import { useNavigate } from "react-router"
import { homeUrl } from "../../res/consts/router_urls_const"
import GoBackButton from "../../components/GoBackButton"
import MessagesPreview from "../../components/MessagesPreview"
import { inject, observer } from "mobx-react"
import ImgBuilder from "../../res/img/imgBuilder"
import MessagesModal from "../../components/MessagesModal"
import NeedToRefreshAlert from "../../components/NeedToRefreshAlert"

import "./styles.scss"

const ObjectiveDetailsPage = (props) =>{
    const {
        objectivesModel:{
            currentObjective, 
            filteredObjectivesList, 
            diagramsData, 
            setCurrentObjective,
            objectivesList
        },
        userModel:{
            filteredUser,
            userList,
            getUserList,
            setFilteredUser,
            setShowPreview,
            setNeedToRefresh,
            needToRefresh
        },
        opportunitiesModel:{
            opportunitiesList,
            setCurrentOppotunity
        },
        chatsModel : {
            getCurrentChatDetails,
            currentChatMessagesData,
            openedChat,
            setOpenedChat,
            chatsWithUnreadedMessages,
            currentChatDetails
        },
        authModel : {
            tableWidth,
            tableSizeParams,
        },
        loadingModel : {
            setLoadingModelState
        },
        serverAPImodel:{baseAPI}
    } = props

    let {id, companyName} = useParams()

    const [currentObjectiveState, setCurrentObjectiveState] = useState([])
    const [unAnsweredQuestions, setUnAnsweredQuestions] = useState([])
    const [answeredQuestions, setAnsweredQuestions] = useState([])
    const [reloadPage, setReloadPage] = useState(false)
    const [currentDiagramId, setCurrentDiagramId] = useState(id)
    const [filteredObjectivesListState, setFilteredObjectivesListState] = useState(filteredObjectivesList)
    const [diagramsDataState, setDiagramsDataState] = useState(diagramsData)
    const [showMessagePreview, setShowMessagePreview] = useState(true)
    const [openModalObject, setOpenModalObject] = useState({show:false})

    const handleCloseMessagePreview = () => {
        setShowMessagePreview(false)
    }
    const navigate = useNavigate()

    const handleGoBack = () =>{
        if(needToRefresh){
            setLoadingModelState(true)
            setFilteredUser(filteredUser)
            getUserList()
            setTimeout(() =>{setFilteredUser(filteredUser)},500)
            setTimeout(() =>{getUserList()},500)
            setTimeout(() => {setLoadingModelState(false)}, 2000)
            setNeedToRefresh(false)
        }
        return navigate(homeUrl)
        
    }

    useEffect(() => {
        if(!userList.length){
            getUserList()
            setReloadPage(true)
        }
        return (() => {!userList.length && navigate(homeUrl)})()
    }, [])

    useEffect(() => {
        if(userList.length && localStorage.getItem("filteredUserId")){
            let formatedUserList = userList.map(item => {return {
                title: item.last_name + ", " + item.first_name, 
                id: item.id, 
                titlesForSearch: [item.last_name + " " + item.first_name, item.first_name + " " +  item.last_name]
            }})
            setFilteredUser(formatedUserList.filter(user => user.id === JSON.parse(localStorage.getItem("filteredUserId")))[0])
        }
    }, [userList])

    useEffect(()=>{
        reloadPage && opportunitiesList.length && objectivesList.length && Object.keys(filteredUser).includes("id") &&
        setCurrentOppotunity({company:opportunitiesList.filter(opportunity => 
            opportunity.id === JSON.parse(localStorage.getItem("currentOppotunityId"))
        )[0]})
    }, [opportunitiesList, objectivesList, filteredUser])

    useEffect(()=>{
        if(reloadPage && filteredObjectivesList.length){
            setCurrentObjective(filteredObjectivesList.filter(objective => 
                objective.id === JSON.parse(localStorage.getItem("currentObjectiveId"))
            )[0])
            let objectivesIteration = filteredObjectivesList.map((objective, index) => {
                if(objective.id === JSON.parse(localStorage.getItem("currentObjectiveId"))){
                    (setCurrentDiagramId(index + 1))
                }
            })
            setFilteredObjectivesListState(filteredObjectivesList)
        }
    }, [filteredObjectivesList])

    useEffect(() => {
        if(currentObjective?.topics && diagramsData.length){            
            let draftCurrentObjectiveState = diagramsData.filter(diagram => diagram.id === currentObjective.id)[0].questionInfo
            setCurrentObjectiveState(draftCurrentObjectiveState)
            setUnAnsweredQuestions(draftCurrentObjectiveState.filter(question => !question.answers[0].text).reverse())
            let answeredQuestionsUnordered = draftCurrentObjectiveState.filter(question => question.answers[0].text)
            let answeredQuestionsOrdered = []
            let answersListLength = answeredQuestionsUnordered.length
            if(answersListLength){
                for(let i = 0; i < answersListLength; i++){
                    let theLatestAnswer = answeredQuestionsUnordered[0]
                    let theLatestAnswerIndex = 0
                    answeredQuestionsUnordered.map((item, index) => {
                        if(item.answers[0].dateWithoutFormating > theLatestAnswer.answers[0].dateWithoutFormating){
                            theLatestAnswer = item
                            theLatestAnswerIndex = index
                        }
                    })
                    answeredQuestionsOrdered.push(theLatestAnswer)
                    answeredQuestionsUnordered.splice(theLatestAnswerIndex,1)
                }
            }
            setAnsweredQuestions(answeredQuestionsOrdered)
        }
    }, [currentObjective, diagramsData])

    useEffect(() => {
        diagramsData.length && setDiagramsDataState(diagramsData)
    }, [diagramsData])

    const handleShowMessageModal = (opportunityId) =>{
        getCurrentChatDetails(opportunityId)
        let newOpenModalObject = {show:true, opportunityId: opportunityId}
        setOpenModalObject(newOpenModalObject)
    }

    useEffect(() => {
        setShowPreview(showMessagePreview)
        localStorage.setItem("openedChat", JSON.stringify(showMessagePreview))
    },[showMessagePreview])

    return <>
        <div className = "row containerObjectiveDetailsPage ms-1 me-1">
            <div className = "col-3 diagramObjectiveDetailsPage p-4">
                <CardCarousel 
                    cardsPerSlide = {1}
                    cardWidth = {150}
                    objectiveDetailsFirstSlide = {currentDiagramId}
                    type = "objectiveDetails"
                    functionOnSliding = {setCurrentObjective}
                    filteredObjectivesList = {filteredObjectivesListState}
                    data = {diagramsDataState.map((objective, objectiveIndex) => 
                        <SingleObjectiveDiagram 
                            diagramTitle = {objective.title} 
                            totalQuestionCount = {objective.totalQuestionCount}
                            answeredQuestionCount = {objective.answeredQuestionCount}
                            index = {objectiveIndex + 1}
                        />
                    )}
                />
                <div className="customStylesMessagesModal" >
                    {(!!Object.keys(currentChatMessagesData).length || openedChat) ? (!(window.innerWidth > 1199) ? 
                        <button variant="primary" 
                            onClick={() => 
                                handleShowMessageModal(localStorage.getItem("currentOppotunityId").substring(1).slice(0, -1))
                            } 
                            className="modalPatternOpenButton"
                            //style = {{opacity:`${openModalObject?.show ? "0" : "1"}`}}
                        >
                            <img src = {(chatsWithUnreadedMessages.includes(currentChatDetails?.id) && !openModalObject.show) ? 
                                ImgBuilder.messageIconActiveWhite : 
                                ImgBuilder.messageIconWhite}
                            />
                            {(showMessagePreview && window.innerWidth > 991 ) ? <span>Opportunity chat</span> : null}
                        </button> :
                        (showMessagePreview ? 
                            <MessagesPreview
                                customStyles = {{position:"relative", minWidth:"unset", marginTop:"50px"}}
                                handleCloseMessagePreview = {handleCloseMessagePreview}
                                draggable = {true}
                                setOpenBigModal = {setOpenModalObject}
                                setOpenModalObject = {setOpenModalObject}
                                opportunityId = {localStorage.getItem("currentOppotunityId").substring(1).slice(0, -1)}
                                openModalObject = {openModalObject}
                            /> : null
                        )
                     ):null }
                </div>
            </div>
            <div className = "col-9 ps-5 questionsContainerObjectiveDetailsPage">
                <div className = "row mb-4" >
                    <GoBackButton text = "← Back to Overview" goBackFunction = {handleGoBack} />
                </div>
                <div className = "row questionsObjectiveDetailsPage">
                    <div className = "col-5" >
                        <div className = "columnTopObjectiveDetailsPage pb-2">
                            <div className = "columnTitleObjectiveDetailsPage">Unanswered</div>
                            <div className = "columnCountObjectiveDetailsPage">{unAnsweredQuestions.length}</div>
                        </div>
                        {unAnsweredQuestions.map(question => <div>
                            <hr className="dropdown-divider"/>
                            <p className = "pt-2 pb-2">{question.questionText}</p>
                        </div>)}
                        <hr className="dropdown-divider"/>
                    </div>
                    <div className = "col-7" >
                        <div className = "columnTopObjectiveDetailsPage">
                            <div className = "columnTitleObjectiveDetailsPage">Answered</div>
                            <div className = "columnCountObjectiveDetailsPage">{answeredQuestions.length}</div>
                        </div>
                        {answeredQuestions.map(question => <div>
                            <p className = "pt-2 pb-2">{question.questionText}</p>
                            {question.answers.map(answer => <div className = {`answerContainerObjectiveDetailsPage ${answer.color}AnswerColor p-2 mb-2`}>
                                <p>{answer.text}</p>
                                <div className = "answerDetails row">
                                    <div className = "col-auto me-auto">{answer.name}</div>
                                    <div className = "col-auto">{answer.date}</div>
                                </div>
                            </div>)}
                            <hr className="dropdown-divider mt-4"/>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
        <MessagesModal
            openModal = {openModalObject.show}
            opportunityId = {openModalObject?.opportunityId}
            withIcon = {false}
            setOpenModalObject = {setOpenModalObject}
            type = "deatilsPage"
            handleShowMessagePreview = {() =>  tableWidth < 961 ? false : setShowMessagePreview(true)}
        />
        <NeedToRefreshAlert tablewidth = {tableSizeParams.width} page = "objectiveDetails"/>
    </>
}

export default inject(
    "objectivesModel",
    "opportunitiesModel",
    "userModel",
    "chatsModel",
    "authModel",
    "serverAPImodel",
    "loadingModel"
)(observer(ObjectiveDetailsPage))