import React, { useEffect, useState } from "react"
import ImgBuilder from "../../res/img/imgBuilder"

import { useNavigate } from "react-router-dom"
import { useParams } from "react-router"
import { homeUrl, login } from "../../res/consts/router_urls_const"
import Breadcrumbs from "../Breadcrumbs"
import { inject, observer } from "mobx-react"

import "../../res/consts/global_styles.scss"

import "./styles.scss"

const Header = (props) => {
    const {
        opportunitiesModel:{
            currentOppotunity
        },
        userModel:{
            filteredUser
        },
        authModel:{
            logout,
            currenManager,
            auth_token,
            setAuthToken,
            getCurrentManeger
        }
    } = props;
    
    const [breadcrumbsTitle, setBreadcrumbsTitle] = useState("")
    const [userName, setUserName] = useState("")
    const [firstLetters, setFirstLetters] = useState("")
    const [smallScreen , setSmallScreen ] = useState();
    const navigate = useNavigate()
    
    let {id, companyName} = useParams()

    const handlebreadcrumbFilter = (url) => {
        navigate(url)
    }

    const resizeHandler = () => {
        setSmallScreen(window.innerWidth >= 811)
    }

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    useEffect(()=>{
        if(currentOppotunity?.company){
            setBreadcrumbsTitle(filteredUser.title)
        }
    }, [currentOppotunity]);

    useEffect(()=>{
        if(filteredUser?.title){
            setBreadcrumbsTitle(filteredUser.title)
        }
        
        if(!auth_token.length && localStorage.getItem("auth_token")){
            setAuthToken(localStorage.getItem("auth_token"))
            getCurrentManeger()
        }
    }, []);
    
    useEffect(() => {
        if(Object.keys(currenManager).includes("first_name")){
            let userNameDraft = currenManager.first_name + " " + currenManager.last_name
            let firstLettersDraft = userNameDraft.split(" ").map(word => word[0])
            setUserName(userNameDraft)
            setFirstLetters(firstLettersDraft)
        }
    }, [currenManager]);

    return <header className = "mainHeader">
        <div className = "mainHeaderContainer">
            <div className = {`row align-items-center contentContainerMainHeader ${companyName ? "withBreadcrumbsMainHeader" : ""}`}>
                {companyName && smallScreen && <div className = "col-auto me-auto containerBreadcrumbsMainHeader">
                     <Breadcrumbs chains = {["Dashboard", breadcrumbsTitle]} urls = {homeUrl}/>
                </div>}
                <img src = {ImgBuilder.mainlogo} 
                    onClick = {() => navigate(homeUrl)} 
                    className = "col-auto me-auto logo"
                />
                <div className = "col-auto initialsMainHeader">
                    <div className = "row align-items-center gx-3">
                        <div className = "col">
                            <div className = "headerInitials">{firstLetters}</div>
                        </div>
                        <div className = "headerUserName col">
                            <div className = "headerUserName">{userName}</div>
                        </div>
                        <div className = "headerLogout col">
                            <div className = "headerLogout" onClick = {() => logout()}>Logout</div>
                        </div>
                    </div>
                </div>
                {companyName && !smallScreen &&
                    <div className = "col-auto me-auto containerBreadcrumbsMainHeader" >
                        <Breadcrumbs chains = {["Dashboard", breadcrumbsTitle]} urls = {homeUrl}/>
                    </div>
                }
            </div>
        </div>
    </header>
}

export default inject(
    "userModel",
    "opportunitiesModel",
    "authModel"
)(observer(Header))