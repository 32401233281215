import * as yup from 'yup'

const LoginSchema = yup.object().shape({
    email: yup.string()
        .min(6, 'Incorrect email')
        .max(48, 'Incorrect email')
        .email('Incorrect email')
        .matches(
            /^([A-Za-z0-9/\@/\./\,/\?/\!/\_/\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/g,
                'Email can only contain Latin letters.'
            )
        .required('Required'),
    password: yup.string()
        //.matches(/^(?=(.*[a-zA-Z]){1,})(?=(.*[\d]){1,}).{6,48}$/, 'Wrong password')
        .min(6, 'Wrong password')
        .max(48, 'Wrong password')
        .matches(
            /^([A-Za-z0-9/\@/\./\,/\?/\!/\#/\№/\%/\^/\:/\&/\?/\*/\(/\)/\-/\+/\=/\{/\}/\"/\'/\</\>/\|/\$/\[/\]/\_/\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/g,
                'Password can only contain Latin letters.'
            )
        .required('Required'),
});

export default LoginSchema