import React from "react"
import BasicPage from "../pages/BasicPage"
import UserInfoPage from "../pages/UserInfoPage"
import ObjectiveDetailsPage from "../pages/ObjectiveDetailsPage"
import LoginPage from "../pages/LoginPage"
import MailLink from "../pages/MailLink"
import ResetPasswordPage from "../pages/ResetPasswordPage"
import { Route, Routes, Navigate } from 'react-router-dom'
import { homeUrl, objectiveDetails, login, mailLink, resetPassword} from "../res/consts/router_urls_const"

const authToken = localStorage.getItem("auth_token")

const MainRouter = (
        <Routes>
            <Route path={login} element = {authToken ? <Navigate to ={homeUrl} /> :<LoginPage/>}/>
            {authToken ?
                <>
                    <Route path={homeUrl} element = {<BasicPage><UserInfoPage/></BasicPage>}/>
                    <Route path={`${objectiveDetails}/:companyName/:id`} element ={<BasicPage><ObjectiveDetailsPage/></BasicPage>}/>
                </>:
                <Route path="*" element={<Navigate to ={login} />}/>
            }
            <Route path={mailLink} element = {<MailLink/>}/> 
            <Route path={resetPassword} element = {<ResetPasswordPage/>}/> 
        </Routes>
)

export default MainRouter