import { observable, makeObservable, makeAutoObservable, action } from 'mobx'
import axios from 'axios'
import { authModel } from './auth'
import { baseAPIAdmin } from '../res/consts/settings'
import { opportunitiesModel } from './opportunities'
import {userModel} from './user'
import { forecastFilterCategories } from '../res/consts/filtersVariants'
import { serverAPImodel } from './serverAPI'



export class ObjectivesModel {
    constructor(){
        this.objectivesList = []
        this.filteredObjectivesList = []
        this.currentObjective = {}
        this.diagramsData = []
        this.objectivesWithCriticalQuestions = []
        makeAutoObservable(this)
    }

    setObjectivesWithCriticalQuestions = (objectives) => {
        let result = objectives.filter(objective => objective.topics[0]?.questions?.length &&
            objective.topics[0].questions.some(question => question.critical)
        )
        this.objectivesWithCriticalQuestions = result.length ? result.map(obj => obj.id) : []
    }

    setObjectivesList = (objectivesList) => {
        this.objectivesList = objectivesList
    } 

    setFilteredObjectivesList = (filteredObjectivesList) => {
        this.filteredObjectivesList = filteredObjectivesList
    }

    setCurrentObjective = (currentObjective) => {
        this.currentObjective = currentObjective
        localStorage.setItem("currentObjectiveId", JSON.stringify(currentObjective.id))
    }

    setDiagramsData = (diagramsData) => {
        this.diagramsData = diagramsData
    }

    getObjectivesList = (userFilteredId) =>{
        const setObjectivesList = this.setObjectivesList
        const setObjectivesWithCriticalQuestions = this.setObjectivesWithCriticalQuestions
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": authModel.auth_token
            },
            params:{
                user_id: userFilteredId
            }
        }
        axios.get(`${serverAPImodel.baseAPIAdmin}objectives`, headers)
        .then(function (response) {
            setObjectivesList(response.data.objectives);
            setObjectivesWithCriticalQuestions(response.data.objectives)
            opportunitiesModel.getOpportunitiesList(userFilteredId)
            forecastFilterCategories.map(category => opportunitiesModel.getOpportunitiesList(userFilteredId, category))
        })
        .catch(function (error) {
            console.log('error', error);
        })
    }

    answerColor = (priorityState) =>{
        let result = "default"
        if(priorityState === 1){
            result = "low" 
        }else if(priorityState === 2){
            result = "high"
        }
        return result
    }

    formatingTime = (dateTime) =>{
        const date = new Date()
        const offset = (date.getTimezoneOffset())/60
        let result = ""
        let dateList = dateTime.split("T")[0].split("-")
        let timeList = dateTime.split("T")[1].split(":")
        result = result + (parseInt(timeList[0] - offset - 12) > 0 ? timeList[0] - offset - 12 : timeList[0] - offset ) + ":"
        result = result + dateTime.split("T")[1].split(":")[1] 
        result = result + (parseInt(timeList[0] - offset - 12) > 0 ? "PM" : "AM")
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        result = monthNames[dateList[1] - 1] + " " + dateList[2] + "," + dateList[0] + " " + result
        return result
    }  

    formatingName = () =>{
        let result = " "
        let filteredUserLS = userModel.filteredUser
        let filteredUserTitleList = filteredUserLS.title.split(", ")
        result = result + filteredUserTitleList[1] + " " + filteredUserTitleList[0]
        return result
    }

    filterAnswersByOpportunity = (answers, opportunity) =>{
        if(answers.length){
            let filteredAnswers = answers.filter(answer => answer.opportunity_id === opportunity.company.id)
            if(filteredAnswers.length){
                return filteredAnswers
            }
            return []
        }
        return []
    }


    checkIfUpdatedQuestionCritical = (userFilteredId, objectiveId) => {
        const objectivesList = this.objectivesList
        const headers = {
            "headers":{
                "accept": "application/json, text/plain, */*",
                "auth-token": authModel.auth_token
            },
            params:{
                user_id: userFilteredId
            }  
        }

        axios.get(`${serverAPImodel.baseAPIAdmin}objectives/${objectiveId}`, headers)
        .then(function(response){
            //scenarios
            /*
                1. if changed objective without critical questions (false)
                2. if count of critical questions in new state not equel to this count in old
                3. if changed answer in critical question
                4. if added or removed answer
                5. if added or removed question
                6. if changed title or position in objective with critical questions
            */

            const activeOpportunity = opportunitiesModel.opportunitiesList.find(item => item.objectives_id_list.split(";").includes(objectiveId));
            if(!activeOpportunity) {
                return;
            }
            
            if(response?.data && response.data.topics[0]?.questions?.length){
                let objectiveUpdatedPreviousState = objectivesList.filter(objective => objective.id === response.data.id).length ?
                    objectivesList.filter(objective => objective.id === response.data.id)[0] :
                    null
                
                let oldStateCriticalQuestions = objectiveUpdatedPreviousState ? // крітікал питання у старому стані обджектівса
                    objectiveUpdatedPreviousState.topics[0]?.questions.filter(question => question.critical) :
                    []

                let objectiveUpdatedCurrentState = response.data

                let newStateCriticalQuestions = objectiveUpdatedCurrentState.topics[0]?.questions.filter(question => question.critical) // крітікал питання у новому стані обджектівса
                oldStateCriticalQuestions.forEach(item => {
                    newStateCriticalQuestions.forEach(el => {
                        if(item.id === el.id) {
                            if(item.answers.length !== el.answers.length) {
                                userModel.setNeedToRefresh(true)
                                return;
                            }
                        }
                    })
                })

                if(!newStateCriticalQuestions.length && !oldStateCriticalQuestions.length){
                    return
                }else if(newStateCriticalQuestions.length !== oldStateCriticalQuestions.length){
                    if (!opportunitiesModel.opportunitiesList.some(opp => opp.id === response.data?.opportunity_id)) {
                        response.data.topics[0]?.questions.forEach(question => {
                            if (question.critical) {
                                userModel.setNeedToRefresh(true)
                                return;
                            }
                        })
                        return false
                    }
                } else if(newStateCriticalQuestions.length === oldStateCriticalQuestions.length) {
                    newStateCriticalQuestions.forEach(item => {
                        oldStateCriticalQuestions.forEach(el => {
                            if(item.id === el.id) {
                              if(item.answers.length !== 0 && el.answers.length === 0) {
                                  userModel.setNeedToRefresh(true);
                                  return;
                                } else if(item.title !== el.title){
                                  userModel.setNeedToRefresh(true);
                                  return;
                              }else if(item.answers.length !==0 && el.answers.length !==0 && item.answers[0].id === el.answers[0].id) {
                                  if(item.answers[0].text !== el.answers[0].text || item.answers[0].state !==el.answers[0].state) {
                                      userModel.setNeedToRefresh(true);
                                      return;
                                    }
                                } else if (item.position !== el.position && (item.critical || el.critical)) {
                                  userModel.setNeedToRefresh(true)
                                  return;
                              }
                            }
                        })
                    })

                    const hasCriticalQuestions = objectiveUpdatedCurrentState && objectiveUpdatedCurrentState.topics.length && objectiveUpdatedCurrentState.topics[0].questions.find(question => question.critical);
                    if(hasCriticalQuestions && objectiveUpdatedPreviousState.title !== objectiveUpdatedCurrentState.title){
                        userModel.setNeedToRefresh(true)
                        return;
                    }

                    return;
                }

                let checkIfChangedAnswers = newStateCriticalQuestions.some(newStateQuestion => {
                    let oldStateQuestion = oldStateCriticalQuestions.filter(oldQuestion => oldQuestion.id === newStateQuestion.id).length ?
                        oldStateCriticalQuestions.filter(oldQuestion => oldQuestion.id === newStateQuestion.id)[0] : null

                    if(!oldStateQuestion){
                        userModel.setNeedToRefresh(true)
                        return
                    }
                    if(oldStateQuestion.answers.length !== newStateQuestion.answers.length){
                        return true
                    }
                    let innerResult = newStateQuestion.answers.some(newAnswer => oldStateQuestion.answers.some(oldAnswer => {
                        if(oldAnswer.id === newAnswer.id){
                            if(oldAnswer.text !== newAnswer.text){
                                return true
                            }else if(oldAnswer.priority !== newAnswer.priority){
                                return true
                            }
                        }
                        return false
                    }))
                    return innerResult
                })
                let checkIfChangedQuestionPosition = oldStateCriticalQuestions.some(question => {
                    return newStateCriticalQuestions.some(newQuestion => (newQuestion.id === question.id) && (newQuestion.postion !== question.position))
                })
                let checkIfChangedObjectiveTitle = objectiveUpdatedPreviousState && (objectiveUpdatedPreviousState.title !== objectiveUpdatedCurrentState.title)
                let checkIfChangedObjectivePosition = (objectiveUpdatedPreviousState && objectiveUpdatedPreviousState.position !== objectiveUpdatedCurrentState && objectiveUpdatedCurrentState.position)
                if(checkIfChangedAnswers || checkIfChangedObjectiveTitle || checkIfChangedObjectivePosition || checkIfChangedQuestionPosition){
                    userModel.setNeedToRefresh(true)
                    return
                }
            }
        })
    }

    buildDiagramsData = (currentItem) =>{
        if(currentItem?.company?.id === opportunitiesModel.currentOppotunity?.company?.id){
            console.log("currentItem", JSON.parse(JSON.stringify(currentItem.company)))
            let removedObjectivesIdList = currentItem.company.removed_objectives_id_list.split(";")
            let objectivesIdList = currentItem.company.objectives_id_list.split(";").filter(obj_id => !removedObjectivesIdList.includes(obj_id))
            let objectivesList = this.objectivesList
            let setFilteredObjectivesList = this.setFilteredObjectivesList
            let setDiagramsData = this.setDiagramsData
            let answerColor = this.answerColor
            let formatingTime = this.formatingTime
            let formatingName = this.formatingName
            let filterAnswersByOpportunity = this.filterAnswersByOpportunity
            const preDraftFilteredObjectives = objectivesIdList.map(objectiveId => 
                objectivesList.filter(objective => objective.id === objectiveId).length ? 
                    objectivesList.filter(objective => objective.id === objectiveId)[0] : null
            ).filter(objective => !!objective).reduce((arr, objective) => {
                if (!arr.find(el => el.id == objective.id)) {
                    arr.push(objective);
                }
                return arr;
            }, []);

            const draftFilteredObjectives = preDraftFilteredObjectives.filter(objective => 
                currentItem.company?.objectives_id_list.includes(objective.id) && 
                //!(currentItem.company?.removed_objectives_id_list && currentItem.company.removed_objectives_id_list.includes(objective.id)) &&
                objective.topics[0]?.questions.length &&
                objective.topics[0]?.questions.some(question => question.critical)
            )
            setFilteredObjectivesList(draftFilteredObjectives)
            let diagramsDataDraft = draftFilteredObjectives.map(objective => {
                let filterCurrentObjective = objective.topics[0].questions.filter(question => question.critical).reverse()

                let questionData = filterCurrentObjective.map(question => {
                    let lastAnswer = {}
                    let filteredAnswersByOpportunity = filterAnswersByOpportunity(question.answers, currentItem)
                    if(filteredAnswersByOpportunity.length){
                        lastAnswer = {answerId:0, answertime: new Date(filteredAnswersByOpportunity[0].updated_at)}
                        let answerIteration = filteredAnswersByOpportunity.map((answer,id) => id !== 0 && 
                            (lastAnswer.answertime < new Date(answer.updated_at)) && 
                            (lastAnswer = {answerId: id, answertime: new Date(answer.updated_at)})
                        )
                    }
                    return{
                        questionText:question.title,
                        answers:[filteredAnswersByOpportunity.length ? {
                            text: filteredAnswersByOpportunity[lastAnswer.answerId].text + 
                            (filteredAnswersByOpportunity[lastAnswer.answerId].updated_at === filteredAnswersByOpportunity[lastAnswer.answerId].created_at ? "" : " (edited)"),
                            color: answerColor(filteredAnswersByOpportunity[lastAnswer.answerId].state),
                            name:formatingName(),
                            date:formatingTime(filteredAnswersByOpportunity[lastAnswer.answerId].updated_at),
                            dateWithoutFormating: new Date(filteredAnswersByOpportunity[lastAnswer.answerId].updated_at)
                        } : {}],
                        color:false
                    }
                })

                return {
                    id: objective.id,
                    title: objective.title,
                    totalQuestionCount: objective.topics[0].questions.reduce((total, question) => {
                        return total + (question.critical ? 1 : 0)
                    }, 0),
                    answeredQuestionCount: objective.topics[0].questions.reduce((total, question) => {
                        return total + ((question.critical && filterAnswersByOpportunity(question.answers, currentItem).length) ? 1 : 0)
                    }, 0),
                    questionInfo:questionData
                }
            })
            setDiagramsData(diagramsDataDraft)
        }
    }
}

export const objectivesModel = new ObjectivesModel()

